import React from 'react';
import Button from '@mui/material/Button';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { css } from '@mui/system';
import './styles.scss'

const DialogTitle = styled('div')(css`
  text-align: center;
  .error-icon {
    color: red;
    margin-top: 32px;
  }
  .error-title {
    font-size: 18px;
    font-weight: bold;
  }
`)

const DialogContent = styled('div')(css`
  font-size: 13px;
  margin: 12px 24px 20px;
  text-align: center;
`)

import {
  Document
} from 'core/models'

export function Upload({
  className = "",
  element,
  orderId,
  description='order document'
}) {
  const fileInput = React.useRef();
  const [fileName, setFileName] = React.useState('');
  const [uploading, setUploading] = React.useState(false);
  const [uploadingError, setUploadingError] = React.useState(false);

  React.useEffect(() => {
    if (element.value !== undefined)
      setFileName(element.value.name)
  }, [])

  const handleChange = (event) => {
    setFileName(event.target.files[0].name);
    element.value = event.target.files[0]
    uploadFile()
  };

  const uploadFile = async () => {
    setUploading(true)
    const {error, result} = await Document.uploadOrderDocument(element.value, orderId, description)
    if (error || !result || result.status != 'success') {
      setUploadingError(true)
    } else {
      element.error = false
    }
    setUploading(false)
  }

  const handleClose = (retry) => {
    setUploadingError(false)
    if (retry) uploadFile()
  }

  return (
    <>
      {element.label !== "" &&
        <Grid container spacing={1} className="grid-container">
          <Grid xs={12} item={true}>
            <label dangerouslySetInnerHTML={{ __html: element.label }}/>
            <label className='required'>{element.required && '*'}</label>
          </Grid>
          <Grid xs={12} item={true} >
            <Button
              className='form-button'
              variant="contained" color={element.error ? "error" : "primary"}
              startIcon={<FileUploadOutlinedIcon />}
              onClick={ ()=> fileInput.current.click()} >
              Upload File
            </Button>
            <input type="file" className="file-input" style={{ display: 'none' }} ref={fileInput} onChange={handleChange}/>
          </Grid>
          <Grid xs={12} item={true} className="file-content">
            {uploading && <CircularProgress size={24} className="progress"/>}
            <label>{fileName}</label>
          </Grid>
        </Grid>
      }
      <Dialog
        open={uploadingError}
        onClose={() => handleClose(false)}
        className='alert-dialog'
      >
        <DialogTitle>
          <div className='error-icon'><ErrorOutlineIcon fontSize="large"/></div>
          <div className='error-title'>There was an error</div>
        </DialogTitle>
        <DialogContent>
            There was an error while processing your file. <br/>
            Make sure you are connected to the internet and <br/>
            have a stable connection during this process
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>Cancel</Button>
          <Button onClick={() => handleClose(true)} autoFocus>Retry</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
