import React from 'react'
import { useLocation } from 'react-router-dom'

export const usePrevious = (value) => {
  const ref = React.useRef()
  React.useEffect(() => { ref.current = value })
  return ref.current
}

/**
 * @callback ActionCallback
 * @param {Location} location
 * @param {Location} prevLocation
 */
/**
 * @param {ActionCallback} action
 */
export const useLocationChange = (action) => {
  const location = useLocation()
  const prevLocation = usePrevious(location)
  React.useEffect(() => {
    action(location, prevLocation)
  }, [location, prevLocation])
}
