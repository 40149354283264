import { Api } from 'core/api/base'
import { errorMessages } from 'config'

export class LightCycleApi extends Api {
	static Method = Object.freeze({
		Login: "Login",
		Register: "Register",
		LoadDocument: "LoadDocument"
	})

	static async request({ method = LightCycleApi.Method.Login, params = null }) {
		try {
			return await Api.post('participant/login', {
				...params,
				// method
			})
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}
}