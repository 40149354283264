import React from 'react'
import { useLocation, useParams } from 'react-router-dom'

import {
  FaceCaptureModal,
  CodeInputModal,
  LandingSplashModal
} from 'components'

import {
  useParticipantAuth
} from 'core'

export function InvitePage(props) {
  let { code } = useParams()
  const {
    verificationMethod,
    phoneLastFour,
    isVisualFailed,
    isMFAFailed,
    startLoginProcess,
    submitUserSelfPhoto,
    submitUserMFACode,
    onCameraError,
    skipToMFA
  } = useParticipantAuth()

  const [isInputFace, setInputFace] = React.useState(false)
  const [isInputCode, setInputCode] = React.useState(false)
  const [isShowSplash, setShowSplash] = React.useState(true)
  const [refreshCount, setRefreshCount] = React.useState(0)

  React.useEffect(() => {
    if(verificationMethod === 1) {
      setInputFace(true)
      setInputCode(false)
    } else if (verificationMethod === 2) {
      setInputCode(true)
      setInputFace(false)
    }
  }, [verificationMethod])

  const startAuthProcess = () => {
    startLoginProcess(code)
    setShowSplash(false)
  }

  return (
    <>
      <LandingSplashModal
        show={isShowSplash}
        onClickStartAuth={() => {startAuthProcess()}}
        />
      <FaceCaptureModal
        show={!isShowSplash && isInputFace}
        onError={(err) => {onCameraError(err); setInputFace(false)}}
        isVisualFailed={isVisualFailed}
        skipToMFA={skipToMFA}
        onCapture={(value) => {submitUserSelfPhoto(value)}}
      />
      <CodeInputModal
        show={!isShowSplash && isInputCode}
        phoneLastFour={phoneLastFour.current}
        onSubmit={(value) => {submitUserMFACode(value)}}
        resendMFACode={() => {skipToMFA()}}
        isMFAFailed={isMFAFailed}
      />
    </>
  )
}

