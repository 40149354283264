
import AbstractGC from './abstract';

export default class User extends AbstractGC {

  async refreshJWT () {
    const { token } = await this.get('authenticate');
    return token;
  }

  async detail () {
    return this.get('user');
  }
  
  async exists (email) {
    
    return this.get(`user/exists/${email}`);
    
  }

}
