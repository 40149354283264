import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import './styles.scss'


export function CustomTag({
  onDelete,
  label,
  color,
  id
}) {
  return (
    <div className="customTagWrapper" style={{backgroundColor:color}}>
        <label className="customTagLabel">{label}</label>
        <CloseIcon className="closeIcon" onClick={()=>onDelete(id)}/>
      </div>
  )
}

export default CustomTag;
