import { BaseModel } from 'core/models/base'

export class DocChangeLog extends BaseModel {
}

export class DocOption extends BaseModel {
  name
  elements = []

  /** @returns {DocOption} */
  static fromJSON(json) {
    const result = super.fromJSON(json)
    if (result) {
      result.name = json.option_name
      result.elements = DocElement.fromJSONArray(result.elements)
    }
    return result
  }
}

export const DocElementType = Object.freeze({
  Media: 'M',
  File: 'F',
  TextField: 'T',
  TextArea: 'TA',
  SelectBox: 'S',
  SSelectBox: 'STATELOOKUP',
  RadioButton: 'R',
  SocialNumber: 'SS',
  DateField: 'D',
  Upload: 'U',
  Checkbox: 'C',
  Label: 'L',
  MediaLink: 'Z'
})

export class DocElement extends BaseModel {
  element_name
  element_type
  label
  required = true
  user_value
  change_log = []
  display = true
  options = []

  /** @returns {DocElement} */
  static fromJSON(json) {
    const result = super.fromJSON(json)
    if (result) {
      result.change_log = DocChangeLog.fromJSONArray(json?.change_log)
      result.options = DocOption.fromJSONArray(json?.options)
    }
    return result
  }
}
