import React from 'react'

import { MenuItem, Select } from '@mui/material';
import { DocumentField } from '../core';
import Grid from '@mui/material/Grid';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import States from 'common/states';
import { DocElementType } from 'core/models'

const menuItemStyle = {
  display: 'flex',
  textAlign: 'left',
  justifyContent: 'flex-start',
  paddingLeft: 20
}

export function SelectBox({
  className = "",
  element,
  orderId
}) {
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (element.value !== undefined)
      setValue(element.value)
    else if (element.user_value)
      setValue(element.user_value)
  }, [])

  return (
    <>
      {element.options !== [] &&
        <Grid container spacing={1} className="grid-container">
          <Grid xs={12} md={7} item={true}>
            <label dangerouslySetInnerHTML={{ __html: element.label }}/>
            <label className='required'>{element.required && '*'}</label>
          </Grid>
          <Grid xs={12} md={5} item={true} >
            <FormControl error={element.error} className="full-width">
              <Select
                className="full-width"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value || ''}
                onChange={(newChange) => {
                  setValue(newChange.target.value);
                  element.value = newChange.target.value
                }}
              >
                {element.element_type === DocElementType.SSelectBox ? 
                  Object.keys(States).map((key, idx) => {
                    return (
                      <MenuItem value={key} key={idx} style={menuItemStyle}>
                        {States[key]}
                      </MenuItem>
                    );
                  })
                  :
                  element.options.map((option, idx) => {
                    return (
                      <MenuItem value={option.option_name} key={idx} style={menuItemStyle}>
                        {option.option_name}
                      </MenuItem>
                    );
                  })
                }
              </Select>
              <FormHelperText>{element.error_message}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      }
      {element.options &&
        element.options.map((option, idx) => (
          option.option_name == value &&
          <React.Fragment key={idx}>
            {option.elements.map((element, index) => {
              return (
                <DocumentField key={index} element={element} orderId={orderId}/>
              )
            })}
          </React.Fragment>
        ))
      }
    </>
  );
}
