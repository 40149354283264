
import { useRef, useEffect, useCallback, createContext, useContext } from 'react';
import useLocalStorage, { useSessionStorage } from '@twipped/hooks/useLocalStorage';
import useWillMount from '@twipped/hooks/useWillMount';
import useForceUpdate from '@twipped/hooks/useForceUpdate';
import useMemoObject from '@twipped/hooks/useMemoObject';
import { catcher } from '@twipped/utils';
import { differenceInDays } from 'date-fns';
import User from 'common/models/user';
import Login from 'common/models/login';
import Company from 'common/models/company';
import { jwtRef } from './jwt';
import { OktaAuth } from '@okta/okta-auth-js';
import { useNavigate } from 'react-router-dom';

const AuthenticationContext = createContext(null);
AuthenticationContext.displayName = 'AuthenticationContext';

export default function useAuthentication () {
  return useContext(AuthenticationContext);
}

const ADMIN = 'ADMIN';
const VENDOR = 'VENDOR';
const CLIENT = 'CLIENT';
const SUPPLIER = 'SUPPLIER';
const BORROWER = 'BORROWER';

const TYPEID_MAP = {
  1: ADMIN,
  2: VENDOR,
  3: CLIENT,
  4: SUPPLIER,
  5: BORROWER,
};

export const USER_TYPE = {
  [ADMIN]:    ADMIN,
  [VENDOR]:   VENDOR,
  [CLIENT]:   CLIENT,
  [SUPPLIER]: SUPPLIER,
  [BORROWER]: BORROWER,
};

export function AuthenticationProvider ({ children }) {
  const navigate = useNavigate();
  const forceUpdate = useForceUpdate();
  const pendingRef = useRef(null);
  const useLogin = new Login();
  const [ localJWT, setLocalJWT ] = useLocalStorage('jwt', null);
  const [ sessionJWT, setSessionJWT, ] = useSessionStorage('jwt', localJWT);
  jwtRef.current = sessionJWT;
  
  const [ user, setUser ] = useSessionStorage('user');
  const [ oktatoken , setOktatoken ] = useSessionStorage('okta');
  const [ urlToken , setUrlToken ] = useSessionStorage('urlToken');
  
  const userLoading = !!user;

  async function loadUserDetails(){
    if (user || !jwtRef.current) return;
    catcher(async () => {
      const u = await (new User).detail();
      setUser(u);
    });
  }

  useEffect(loadUserDetails, [ jwtRef.current ]);

  function task (p) {
    // pendingRef contains any promise in transit.
    var lastp;
    if (pendingRef.current) {
      lastp = pendingRef.current = Promise.allSettled([ pendingRef.current, p ]);
    } else {
      lastp = pendingRef.current = Promise.allSettled([ p ]);
    }
    lastp.then(() => {
      // when the promise resolves, check if the promise on the ref is the one
      // we just created. If it is, set the ref to null and update the component.
      if (pendingRef.current !== lastp) return;
      pendingRef.current = null;
      forceUpdate();
    }, () => null);
    return p;
  }

  useWillMount(() => {
    // If a JWT exists in local storage, validate it when the provider mounts.
    if (!jwtRef.current) return;
    try {
      var tokenbody = JSON.parse(atob(jwtRef.current.split('.')[1]));
    } catch (e) {
      tokenbody = null;
    }

    const dateExpires = tokenbody && tokenbody.exp && new Date(tokenbody.exp * 1000);
    const dateSigned = tokenbody && tokenbody.wen && new Date(tokenbody.wen * 1000);

    if (!dateExpires || dateExpires < new Date) {
      jwtRef.current = null;
      return;
    }

    // if the token is more than a day old, lets just go ahead and refresh it.
    if (!dateSigned || differenceInDays(new Date, dateSigned) > 1) {
      catcher(async () => {
        const jwt = jwtRef.current = await task((new User).refreshJWT());
        setSessionJWT(jwt);
        if (localJWT) setLocalJWT(jwt);
      });
      return;
    }
  });


  const login = useCallback(async (email, password, remember) => {
    const token = await useLogin.getJWTWithEmailPassword(email, password);
    if (!token) return false;

    setSessionJWT(token);
    if (remember) setLocalJWT(token);

    const u = await (new User()).detail();
    setUser(u);

    return { 'success': true, 'userType': u.type.id };
    // return true;
  });

  const logout = useCallback(async () => {
    
    if(oktatoken)
    {
      const { settings } = await (new Company).current();
      
      const oktaAuth = new OktaAuth({
        issuer: 'https://'+settings.oktaDomain+'.okta.com/oauth2/default',
        clientId: settings.oktaClientId,
        redirectUri: window.location.origin + '/r/externallogin/callback',
        postLogoutRedirectUri : window.location.origin + '/r/login'
      });
      
      await oktaAuth.signOut();
      setOktatoken(null);
    }
    
    setSessionJWT(null);
    setLocalJWT(null);
    jwtRef.current = null;
    forceUpdate();
    navigate('/login');
  });

  const provider = useCallback(async (email) => {
    
    return await useLogin.getAuthProvider(email);

  });
  
  const createSessionFromExternal = useCallback( async (email,okta_token) => {
    
    const token = await useLogin.getJWTfromOKTA(email,okta_token);
    
    setSessionJWT(token);
    setLocalJWT(token);

    const u = await (new User()).detail();
    setUser(u);
    
    setOktatoken(okta_token);

    return { 'success': true, 'userType': u.type.id };
    // return true;
  
    
  });

  const createSessionFromUrl = useCallback( async (urlKey) => {
    
    const token = await useLogin.getJWTfromUrlToken(urlKey);
    
    setSessionJWT(token);
    setLocalJWT(token);

    const user = await (new User()).detail();
    setUser(user);
    
    setUrlToken(token);

    return { 'success': true, ...user };
    
  });
  
  const checkUserExists = useCallback( async (email) => {
    
    const { available } = await (new User).exists(email);
    
    return available;
    
  });

  const loading = !!pendingRef.current || userLoading;
  const isAuthenticated = !!jwtRef.current;
  const userType = TYPEID_MAP[user?.type?.id];

  const canHas = useCallback( async (needs) => {
    // yes I memed, what are you gonna do about it?

    await loadUserDetails();

    if (!userType) return false;
    if (!needs) return true;
    if (userType === ADMIN) return true;
    if (Array.isArray(needs)) {
      return needs.includes(userType);
    }
    return needs === userType;
  }, [ userType ]);

  const context = useMemoObject({
    loading,
    isAuthenticated,
    user,
    userType,
    canHas,
    login,
    logout,
    provider,
    createSessionFromExternal,
    createSessionFromUrl,
    checkUserExists
  });

  return <AuthenticationContext.Provider value={context}>{children}</AuthenticationContext.Provider>;
}

export function isUserType (required = null) {
  const { canHas } = useAuthentication();
  return canHas(required);
}
