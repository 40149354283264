import { Api } from 'core/api'
import { errorMessages } from 'config'
import { BaseModel } from 'core/models/base'

export class Vendor extends BaseModel {
	
	/** @returns {User} */
	static fromJSON(json) { return super.fromJSON(json) }

	// static async getAvailabeVendors(orderId) {
	// 	try {
	// 		const data =  await Api.get(`/vendors?orderid=${orderId}`)
	// 		console.log('vender data', data);
	// 		return {data}
	// 	} catch (error) {
	// 		return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
	// 	}
	// }

}
