export class Storage {
	static Keys = Object.freeze({
		AccessToken: "participant-accesssToken",
		RefreshToken: "participant-refreshToken",
	})

	static set(key, value) {
		if (value) sessionStorage.setItem(key, value)
		else sessionStorage.removeItem(key)
	}

	static get(key, defaultValue = undefined) {
		return sessionStorage.getItem(key) || defaultValue
	}

	static get accessToken() { return Storage.get(Storage.Keys.AccessToken) }
	static set accessToken(value) { Storage.set(Storage.Keys.AccessToken, value) }

	static get refreshToken() { return Storage.get(Storage.Keys.RefreshToken) }
	static set refreshToken(value) { Storage.set(Storage.Keys.RefreshToken, value) }

	static get timezone() { return Storage.get(Storage.Keys.Timezone) }
	static set timezone(value) { Storage.set(Storage.Keys.Timezone, value) }
}