import { BaseModel } from 'core/models/base'
import { DocGroup } from 'core/models/document/group'

export class DocSection extends BaseModel {
  section_id
  section_label
  groups = []

  /** @returns {DocSection} */
  static fromJSON(json) {
    const result = super.fromJSON(json)
    if (result) {
      result.groups = DocGroup.fromJSONArray(json?.groups)
    }
    return result
  }
}
