
import AbstractGC from './abstract';

export default class Company extends AbstractGC {

  async current () {
    return await this.get('company');
  }

  async get (companyid) {
    return this.get(`company/${companyid}`);
  }

}
