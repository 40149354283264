
import Box from '@mui/material/Box';
import useCompany from 'common/company';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

export default function CompanyLogo ({ sx, ...props }) {
  const { loading, branding } = useCompany();

  if (loading || !branding) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
        <CircularProgress />
      </Box>
    );
  }
  else if (loading && branding && branding.logoPrimary == "")
  {
    branding.logoPrimary = "voxturOMS.png";
  }

  return (
    <Box
      {...props}
      sx={{
        ...sx,
        flexGrow: 1,
        backgroundPosition: 'center center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(/common/companyfiles.php?logo=1&filename=${branding.logoPrimary})`,
        height: 54,
      }}
    />
  );
}
CompanyLogo.propTypes = {
  sx: PropTypes.object,
};
