
import axios from 'axios';
const APIROOT = process.env.API_ENDPOINT;
import { warn } from '@twipped/utils';
import { resolve } from 'common/path';
import getJWT from 'common/authentication/jwt';

export default class AbstractGC {
  constructor () {
    const jwt = getJWT();

    const headers = {};
    if (jwt) headers.Authorization = 'Bearer ' + jwt;

    this._axios = axios.create({
      baseURL: resolve(APIROOT, this.basePath || ''),
      timeout: 10000,
      headers,
    });

    const _success = (res) => (res.data);

    const _catch = (err) => {
      if (err.response) {
        warn(`GRANDCENTRAL ${err.response.status} RESPONSE:`, err.response.data);
        return err.response.data;
      } else if (err.request) {
        warn('GRANDCENTRAL REPLIED WITH AN EMPTY RESPONSE');
        return null;
      }

      throw err;
    };

    for (const k of [ 'request', 'get', 'delete', 'head', 'options', 'post', 'put', 'patch', 'getUri' ]) {
      this[k] = (...args) => (
        this._axios[k](...args).then(_success, _catch)
      );
    }
  }
}
