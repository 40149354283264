import { Image } from '@react-pdf/renderer'
import compose from './compose'

export const PDFImage = ({ className, value, width, styles={} }) => {
  return (
    <Image
      style={styles ? styles : {...compose(`image ${className ? className : ''}`), maxWidth: width, ...styles}}
      src={value}
    />
  )
}
