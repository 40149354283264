const colorDark = '#222'
const colorDark2 = '#666'
const colorGray = '#e3e3e3'
const colorWhite = '#fff'
const colorSecondary = '#1c7252'
const colorBlack = '#000'

const styles = {
  'dark': {
    color: colorDark,
  },

  'white': {
    color: colorWhite,
  },

  'bg-dark': {
    backgroundColor: colorDark2,
  },

  'bg-gray': {
    backgroundColor: colorGray,
  },

  'secondary': {
    color: colorSecondary,
  },

  'black': {
    color: colorBlack,
  },

  'flex': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },

  'w-auto': {
    flex: 1,
    paddingRight: '8px',
  },

  'ml-30': {
    flex: 1,
  },

  'w-100': {
    width: '100%',
  },

  'w-90': {
    width: '90%',
  },

  'w-80': {
    width: '90%',
  },

  'w-70': {
    width: '70%',
  },

  'w-50': {
    width: '50%',
  },

  'w-55': {
    width: '55%',
  },

  'w-45': {
    width: '45%',
  },

  'w-60': {
    width: '60%',
  },

  'w-40': {
    width: '40%',
  },

  'w-35': {
    width: '35%',
  },

  'w-10': {
    width: '10%',
  },

  'w-13': {
    width: '13%',
  },

  'w-17': {
    width: '17%',
  },

  'w-15': {
    width: '15%',
  },

  'w-20': {
    width: '20%',
  },

  'w-25': {
    width: '25%',
  },

  'w-30': {
    width: '30%',
  },

  'row': {
    borderBottom: `1px solid ${colorGray}`,
  },

  'border': {
    border: `1px solid ${colorGray}`,
  },

  'mt-40': {
    marginTop: '40px',
  },

  'mt-30': {
    marginTop: '30px',
  },

  'mt-20': {
    marginTop: '20px',
  },

  'mt-10': {
    marginTop: '10px',
  },

  'mb-5': {
    marginBottom: '5px',
  },

  'mb-10': {
    marginBottom: '10px',
  },

  'p-4-8': {
    padding: '4px 8px',
  },

  'p-4': {
    padding: '4px',
  },

  'p-5': {
    padding: '5px',
  },

  'p-5-10': {
    padding: '5px 10px',
  },

  'pb-10': {
    paddingBottom: '10px',
  },

  'right': {
    textAlign: 'right',
  },

  'bold': {
    fontWeight: 'bold',
  },

  'fs-16': {
    fontSize: '16px',
  },

  'fs-20': {
    fontSize: '20px',
  },

  'fs-45': {
    fontSize: '45px',
  },

  'page': {
    fontFamily: 'Nunito',
    fontSize: '12px',
    color: '#555',
    padding: '40px 35px',
  },

  'span': {
    padding: '2px 12px 2px 0',
  },

  'logo': {
    display: 'block',
  }
}

export default styles
