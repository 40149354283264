import { BaseModel } from 'core/models/base'
import { DocElement } from 'core/models/document/element'

export class DocGroup extends BaseModel {
	group_type
	elements = []

	/** @returns {DocGroup} */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		if (result) {
			result.elements = DocElement.fromJSONArray(json.elements)
		}
		return result
	}
}
