import React from 'react';

import './styles.scss'

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

export function Sidebar({
  contents
}) {
  return (
    <Card className='sidebar'>
      <CardContent>
        <h6>File number</h6>
        <p>{contents && contents["file_number"]}</p>

        <h6>Title / Escrow Company</h6>
        <p>
          {contents && contents["title"]}<br/>
          Company - {contents && contents["escrow_company"]}
        </p>

        <h6>Contact information</h6>
        <p>
          Name: {contents && contents["contact_name"]}<br/>
          Phone1: {contents && contents["contact_phone"]}<br/>
          Phone2: {contents && contents["contact_altphone"]}<br/>
          Email: {contents && contents["contact_email"]}<br/>
        </p>

        {/* {
          contents && 
          Object.entries(contents).map(([key, value]) => {
            const keys = key.replace(/[/_/]/g, ' ')
          return(
            <div key={key}>
              <h6 style={{textTransform:'capitalize'}}>{keys}</h6>
              <p>{value}</p>
            </div>
          )})
        } */}
      </CardContent>

    </Card>
  );
}

export default Sidebar;
