
import { createContext, useContext } from 'react';
import { useSessionStorage } from '@twipped/hooks/useLocalStorage';
import useWillMount from '@twipped/hooks/useWillMount';
import useMemoObject from '@twipped/hooks/useMemoObject';
import Company from 'common/models/company';
import { catcher } from '@twipped/utils';

const CompanyContext = createContext(null);
CompanyContext.displayName = 'CompanyContext';

export default function useCompany () {
  return useContext(CompanyContext);
}

export function CompanyProvider ({ children }) {
  const [ company, setCompany ] = useSessionStorage('company');

  useWillMount(() => {
    if (company?.companyid) return;
    catcher(async () => {
      const c = await (new Company).current();
      setCompany(c);
    });
  });

  const context = useMemoObject({
    ...company,
    loading: !company?.companyid,
  });

  return <CompanyContext.Provider value={context}>{children}</CompanyContext.Provider>;
}
