
import { useState,useRef,useEffect } from 'react';
import { Link, useNavigate , useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import Alert from '@mui/material/Alert';
import useAuthentication from 'common/authentication';
import { catcher } from '@twipped/utils';
import CompanyLogo from 'common/ui/CompanyLogo';

export default function LoginPage () {
  const { login , provider } = useAuthentication();
  const navigate = useNavigate();
  const [ { loading, error }, setAttempt ] = useState({ loading: false, error: false });
  const [ slideToPassword , setSlideToPassword ] = useState(false);
  const [ redirectPath , setRedirectPath ] = useState(null);
  const userNameRef = useRef(null);
  const slidecontainerref = useRef(null);
  const passwordInputRef = useRef(null);
  const location = useLocation();

  if(redirectPath === null && location.state !== null && location.state.from !== null && location.state.from.pathname.match(/^\/vendor/))
  {
    setRedirectPath(location.state.from.pathname);
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    
    if(data.get('email') == "" || data.get('password') == "") {
      setAttempt({ loading : false , error: true});
      return;
    }

    catcher(async () => {
      setAttempt({ loading: true, error: false });
      const { success , userType } = await login(data.get('email'), data.get('password'), true);

      if (success && userType === 1) {
        navigate('/admin');
        return;
      }
      if (success && userType === 2)
      {
        if(redirectPath === null)
        {
          navigate('/vendor');
        }
        else
        {
          navigate(redirectPath);
        }
        return;
      }
      if (success && userType === 3) {
        navigate('/client');
        return;
      }

      setAttempt({ loading: false, error: true });
    });
  };

  const handleUsernameKeyEvent = async (event) => {
    
    if([9,13].indexOf(event.keyCode) !== -1)
    {
      event.preventDefault();
      handleUsernameEvent();
    }
  };
  
  const handleUsernameEvent = async (event) => {

      if(event)
      {
        event.preventDefault();
      }
      
      const username = userNameRef.current.value;
      
      var userprovider = await provider(username);

      if(userprovider === "EXT")
      {
        const encodedusername = btoa(username)
        
        navigate("/externallogin?token="+encodedusername);
        return;
      }
      setSlideToPassword(true);
  };

  const focusPasswordAfterTransition = () => {

    if(slideToPassword)
    {
      passwordInputRef.current.focus();
    }
    
  }

  return (
    <Container component="main" maxWidth="xs" sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Paper elevation={3} sx={{ p: 2 }}>
        <CompanyLogo />
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} ref={slidecontainerref}>
          {!!error && (
            <Alert severity="error">Login Failed</Alert>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="off"
            autoFocus
            onKeyDown={handleUsernameKeyEvent}
            inputRef={userNameRef}
          />
          <Slide direction="left" in={slideToPassword}  container={slidecontainerref.current} onEntered={focusPasswordAfterTransition}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              inputRef={passwordInputRef}
            />
          </Slide>
          <LoadingButton
            type="submit"
            loading={loading}
            fullWidth
            loadingPosition="end"
            endIcon={<LoginOutlinedIcon />}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={ (event) => {
              if(!slideToPassword)
              {
                handleUsernameEvent(event);
              }
            }}
          >
            Sign In
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <a href="/common/forgotpassword.php">Forgot password?</a>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}

