import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import InputMask from "react-input-mask";

export function TextFieldInput({
  className = "",
  element,
}) {
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    if (element.value !== undefined)
      setValue(element.value)
    else if (element.user_value)
      setValue(element.user_value)
  }, [])

  const getElementMask = (element) => {
    let mask = '', mask_char = '_'
    switch(element.element_name) {
      // Tax ID Number (##-#######)
      case 'core_tid':
      case 'core_second_taxid':
      case 'e_core_tax_id':
        mask = '99-9999999'
        break

      // Phone (###) ###-####
      case 'core_home_phone':
      case 'core_work_phone':
      case 'core_mobile_phone':
      case 'core_spouse_mobile':
      case 'core_second_phone':
      case 'core_re_company_phone':
      case 'core_att_company_phone':
      case 'core_lender_phone1':
      case 'core_lender_phone2':
      case 'core_association_phone':
      case 'core_association_phone2':
      case 'core_funds_specific_escrow_ph':
      case 'core_mail_phone':
      case 'core_pickup_phone':
        mask = '(999) 999-9999'
        break

      // Zip Code
      case 'core_att_company_zip':
      case 'core_mailing_zip':
      case 'core_post_zip':
      case 'core_att_company_zip':
      case 'core_other_mail_zip':
      case 'core_mail_zip':
        mask = '99999'
        break

      // Credit CVV Code
      case 'core_cc_3v':
      case 'core_cc_mc':
      case 'core_cc_d':
      case 'core_cc_3_mc':
      case 'core_cc_3_v':
        mask = '999'
        break

      case 'core_cc_4':
        mask = '9999'
        break

      // Credit Expiration (mm/YYYY)
      case 'core_cc_exp':
        mask = '99/9999'
        break

      case 'core_cc_number':
        mask = '9999999999999999'
        mask_char = ' '
        break

      case 'core_utility_power_co_account':
        mask = '9999999999'
        mask_char = ' '
        break
      case 'core_utility_sewer_co_account':
        mask = '99999999999999'
        mask_char = ' '
        break
      case 'core_utility_garbage_co_account':
        mask = '999999999999999999'
        mask_char = ' '
        break
    }
    return {mask, mask_char}
  }

  const handleChange = event => {
    if (element.element_name == 'core_assoication_dues' || element.element_name == 'core_association_dues2') {
      element.value = event.target.value.replace(/[^0-9.]/g, '')
    } else {
      element.value = event.target.value
    }
    setValue(element.value)
  };

  let placeholders = [], placeholder = ''
  if (element.verification.verification_source.length > 0) {
    for (let source of element.verification.verification_source)
      for (let source_value of source.value)
        if (placeholders.indexOf(source_value) < 0)
          placeholders.push(source_value)
    if (placeholders.length == 1)
      placeholder = placeholders[0]
  }
  const {mask, mask_char} = getElementMask(element)
  return (
    <Grid container spacing={1} className="grid-container">
      <Grid xs={12} md={7} item={true}>
        <label dangerouslySetInnerHTML={{ __html: element.label }}/>
        <label className='required'>{element.required && '*'}</label>
      </Grid>
      <Grid xs={12} md={5} item={true} >
        {mask == '' ? (
          placeholders.length > 0 ? 
        <Autocomplete
          id="free-solo-demo"
          freeSolo
          disableClearable
          options={placeholders}
          value={value}
          onInputChange={(event, newValue) => {
            setValue(newValue)
            element.value = newValue
          }}
          renderInput={
            (params) => {
                params.inputProps.autoComplete = "off"
                params.inputProps.role = "presentation"
                return (<TextField {...params} className="full-width" error={element.error} helperText={element.error_message}/>)
            }
          }
        />
          :
          <TextField 
            error={element.error}
            id="outlined-basic"
            className="full-width"
            variant="outlined"
            onChange={handleChange}
            value={value}
            inputProps={{
              role: "presentation",
              autoComplete: 'off',
            }}
            helperText={element.error_message}
            />
        ) : (
          <InputMask
              mask={mask}
              value={value}
              maskChar={mask_char}
              onChange={handleChange}
            >
              {() => <TextField 
                error={element.error}
                className="full-width"
                variant="outlined"
                helperText={element.error_message}
                inputProps={{
                  role: "presentation",
                  autoComplete: 'off',
                }}
              />}
            </InputMask>
      )}
      </Grid>
    </Grid>
  );
}
