import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Storage } from 'utils'

import {
  User
} from 'core/models'

const AuthContext = React.createContext({})

export function ParticipantAuthProvider({
  children
}) {
  let navigate = useNavigate();

  const invitationCode = React.useRef(null)
  const userEmail = React.useRef(null)
  const phoneLastFour = React.useRef(null)
  /*
    1: Visual verification
    2: MFA verifiation
    0: Gerneral one
  */
  const [verificationMethod, setVerificationMethod] = React.useState(0)
  const [isVisualFailed, setIsVisualFaield] = React.useState(false)
  const [isMFAFailed, setIsMFAFailed] = React.useState(false)
  const currentUser = React.useRef(null)
  const currentSession = React.useRef(null)

  async function startLoginProcess(code) {
    invitationCode.current = code
    Storage.accessToken = ""
    await checkInviteCode(code)
  }

  async function checkInviteCode(code) {
    const { error, data } = await User.checkInviteCode({ code })
    if (error) {
      alert(error)
    } else {
      if(data.status === 'success') {
        userEmail.current = data.response.user.email
        phoneLastFour.current = data.response.last_four_mobile
        if(data.response.match_photo) {
          setVerificationMethod(1)
        } else {
          setVerificationMethod(2)
        }
      } else if(data.status === 'fail') {
        alert(data.message)
      }
    }
  }

  async function submitUserSelfPhoto(img) {
    setIsVisualFaield(false);
    const { error, data } = await User.login(
      {
        invite: invitationCode.current.toString(),
        email: userEmail.current,
        photo: img,
        temp_photo_match: false
      })
    if (error) {
      alert(error)
    } else {
      if(data.status === 'success') {
        currentUser.current = data.response.user
        currentSession.current = data.response.session
        Storage.accessToken = data.response.session.token
        navigate("/home", { replace: true });
      } else if(data.status === 'fail') {
        setIsVisualFaield(true);
      }
    }
  }

  async function onCameraError(err) {
    await skipToMFA()
  }

  async function skipToMFA() {
    setVerificationMethod(2)
    const { error, data } = await User.toMFA({code: invitationCode.current})
    if (error) {
    } else {
      if(data.status === 'success') {
      } else if(data.status === 'fail') {
      }
    }
  }

  async function submitUserMFACode(code) {
    const { error, data } = await User.login(
      {
        invite: invitationCode.current.toString(),
        email: userEmail.current,
        mfa_value: code,
        temp_photo_match: false
      })
    if (error) {

    } else {
      if(data.status === 'success') {
        currentUser.current = data.response.user
        currentSession.current = data.response.session
        Storage.accessToken = data.response.session.token
        navigate("/home", { replace: true });
        setIsMFAFailed(false);
      } else if(data.status === 'fail') {
        setIsMFAFailed(true);
      }
    }
  }

  const memoedValue = React.useMemo(() => ({
    verificationMethod,
    isMFAFailed,
    isVisualFailed,
    phoneLastFour,
    currentUser,
    currentSession,

    startLoginProcess,
    submitUserSelfPhoto,
    submitUserMFACode,
    onCameraError,
    skipToMFA
  }), [invitationCode, verificationMethod, isMFAFailed, userEmail, phoneLastFour, currentUser, currentSession, isVisualFailed])

  return (
    <AuthContext.Provider value={memoedValue}>
      {children}
    </AuthContext.Provider>
  )
}

export function useParticipantAuth() {
  return React.useContext(AuthContext)
}
