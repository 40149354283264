import React from 'react'

import './styles.scss'

function getId(url) {
  var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  var match = url.match(regExp);

  if (match && match[2].length == 11) {
      return match[2];
  } else {
      return 'error';
  }
}

export function MediaField({
  className = "",
  element,
  value,
  onChange = (value) => { }
}) {
  return (
    <>
      {element.option !== [] &&
        <div className='video'>
          { element.options.map((option, idx)=> {
            var mediaSource = option.option_name;
            if (mediaSource.indexOf("http://") == 0 || mediaSource.indexOf("https://") == 0)
              mediaSource += 'controls=0&amp;autoplay=1'
            else
              mediaSource = `https://${mediaSource}?controls=0&amp;autoplay=1`
            return(
              <object key={idx}>
                <embed className='media-frame' src={mediaSource}/>
              </object>
            )
          })}
        </div>
      }
    </>

  )
}
