
import { Route, Navigate } from 'react-router-dom';
import { lazy } from 'react';

const HomePage = lazy(() => import('routes/home'));

export default function homeRoutes({ canHas }) {
  return (
    <Route path="home/*" element={<HomePage />} />
  );
}

