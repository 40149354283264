import React from 'react';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { DocumentField } from '../core';
import Grid from '@mui/material/Grid';

export function RadioButton({
  className = "",
  element,
  orderId
}) {
  const [radioValue, setRadioValue] = React.useState(0);

  React.useEffect(() => {
    if (element.value !== undefined)
      setRadioValue(element.value)
    else if (element.user_value)
      setRadioValue(element.user_value)
  }, [])

  const handleChange = (event) => {
    setRadioValue(event.target.value);
    element.value = event.target.value
  };

  return (
    <>
      {element.options !== [] &&
        <Grid container spacing={1} className="grid-container">
          <Grid xs={12} md={7} item={true}>
            <label dangerouslySetInnerHTML={{ __html: element.label }}/>
            <label className='required'>{element.required && '*'}</label>
          </Grid>
          <Grid xs={12} md={5} item={true} className="radio-container">
            <RadioGroup
              row aria-labelledby="demo-row-radio-buttons-group-label"
              name="radio-button-demo"
            >
              {element.options.map((option, idx) => {
                return (
                  <div key={idx}>
                  {element.error ? 
                    <FormControlLabel
                      checked={radioValue === option.option_name}
                      onChange={handleChange}
                      value={option.option_name}
                      control={<Radio sx={{
                        color: 'red',
                        '&.Mui-checked': {
                          color: 'red',
                        },
                      }}/>}
                      label={option.option_name}
                      className="error"
                    >
                      {option.option_name}
                    </FormControlLabel>
                    :
                    <FormControlLabel
                      checked={radioValue === option.option_name}
                      onChange={handleChange}
                      value={option.option_name}
                      control={<Radio />}
                      label={option.option_name}
                    >
                      {option.option_name}
                    </FormControlLabel>
                  }
                  </div>
                )
              })}
            </RadioGroup>
          </Grid>
        </Grid>
      }
      {element.options &&
        element.options.map((option, idx) => (
          option.option_name == radioValue &&
          <React.Fragment key={idx}>
            {option.elements.map((element, index) => {
              return (
                <DocumentField key={index} element={element} orderId={orderId}/>
              )
            })}
          </React.Fragment>
        ))
      }
    </>
  );
}
