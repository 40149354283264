
import axios from 'axios';
const APIROOT = process.env.API_ENDPOINT;
import { warn } from '@twipped/utils';

export default class Login {
  async getJWTWithEmailPassword (email, password) {
    var res;
    try {
      res = await axios({
        method: 'get',
        url: APIROOT + '/authenticate',
        auth: {
          username: email,
          password,
        },
      });
    } catch (e) {
      if (e.response) res = e.response;
      else throw e;
    }
  
    if (res.status === 401) {
      return false;
    }
  
    if (res.status !== 200) {
      warn(`Received a ${res.status} response from grandcentral.`, res.data);
      return null;
    }
  
    return res.data.token;
  }
  
  async getJWTfromOKTA( email , token ){
    
    var res;
    try {
      res = await axios({
        method: 'post',
        url: APIROOT+'/okta',
        data:{
          email,
          token
        }
      })
    }
    catch (e){
      if (e.response) res = e.response;
      else throw e;
    }

    if (res.status === 401) {
      return false;
    }
  
    if (res.status !== 200) {
      warn(`Received a ${res.status} response from grandcentral.`, res.data);
      return null;
    }
  
    return res.data.token;
  }
  
  async getJWTfromUrlToken( token ){
    
    var res;
    try {
      res = await axios({
        method: 'post',
        url: APIROOT+'/vendorinvite',
        data:{
          token
        }
      })
    }
    catch (e){
      if (e.response) res = e.response;
      else throw e;
    }

    if (res.status === 401) {
      return false;
    }
  
    if (res.status !== 200) {
      warn(`Received a ${res.status} response from grandcentral.`, res.data);
      return null;
    }
  
    return res.data.token;
  }

  async getAuthProvider(email){
    var res;
    try {
      res = await axios({
        method: 'get',
        url: APIROOT+'/provider/'+email
      });
    } catch (e) {
      if (e.response) res = e.response;
      else throw e;
    }
    
    if(res.status !== 200) {
      warn(`Received a ${res.status} response from grandcentral.`, res.data);
      return null;
    }
    
    return res.data.provider;
  }

}