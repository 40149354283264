import React from "react";
import Vendor from "../../common/models/vendor";

import { useSnackbar } from 'notistack';

import { useNavigate } from 'react-router-dom';

const VendorContext = React.createContext({});

export const VendorProvider = React.memo(({ children }) => {
  const [stateVendor, setStateVendor] = React.useState(0);
  const [addVendorLoading, setAddVendorLoading] = React.useState(false);
  const [addVendorBtnLoading, setAddVendorBtnLoading] = React.useState(false);
  const [assignVendorLoading, setAssignVendorLoading] = React.useState(false);
  const [availabeVendors, setAvailableVendors] = React.useState([]);
  const [vendorSearchResults, setVendorSearchResults] = React.useState([]);
  const [vendorDetail, setVendorDetail] = React.useState({});
  const [selectedVendor, setSelectedVendor] = React.useState({});
  const [states, setAllStates] = React.useState([]);
  const [vendorType, setVendorType] = React.useState([]);
  const [formValue, setFormValue] = React.useState({certificates:[], insurance:[]});
  const [addVendorBtn, setAddVendorBtn] = React.useState(true);
  const [tabValue, setTabValue] = React.useState(0);
  const [vendorApprove, setvendorApprove] = React.useState(null);
  const [vendorProfile, setVendorProfile] = React.useState(false);
  const [profileData, setProfileData] = React.useState({certificates:[{}]});
  const [vendorLoading, setVendorLoading] = React.useState(false);
  const [vendorDetailsLoading, setVendorDetailsLoading] = React.useState(false);
  const [feeSchedules, setFeeSchedules] = React.useState();
  const [orderType, setOrderType] = React.useState();
  const [feeOpen, setFeeModalOpen] = React.useState({open:false, title:'', item: null});


  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();


  const handleAlert = (msg, variant) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(msg, { variant });
  };

  const handleResponseAlert = (response, msg = '') => {
    if(response.status && response.status === 'success'){
      handleAlert(msg, 'success')
    } else {
      msg = response && response.errors && response.errors[0].detail || 'Internal Server Error!'
      handleAlert(msg, 'error')
    }
  };

  const deleteLicenceField = (index) => {
    const certificates = [...profileData?.certificates];
    certificates.splice(index, 1);
    setProfileData({
      ...profileData,
      certificates: certificates
    });
  };

  const addLicenceField = () => {
    setProfileData({
      ...profileData,
      certificates: [...profileData?.certificates, {}]
    });
  };

  const deleteVendorLicense = (data) => {
    const vendor = new Vendor();
    vendor.deleteVendorCertificate(data)
  }

  const uploadVendorCertificate = async (file, docType, state=null) => {
    const vendor = new Vendor();
    return await vendor.uploadVendorDocument(file, docType, state)
  }

  const downloadVendorCertificate = async (data) => {
    const vendor = new Vendor();
    return await vendor.downloadVendorCertificate(data)
  }

  const downloadVendorInsurance = async (data) => {
    const vendor = new Vendor();
    return await vendor.downloadVendorInsurance(data)
  }

  const searchStateVendors = (searchText) => {
    searchText = searchText.toLowerCase();
    const results = availabeVendors.filter(v => {
      return (
        v.firstname.toLowerCase().includes(searchText) ||
        v.lastname.toLowerCase().includes(searchText) ||
        v.phone.includes(searchText) ||
        v.vendor_type.toLowerCase().includes(searchText)
        )
    });
    setVendorSearchResults(results);
  };

  const getStateVendors = async (state) => {
    setAvailableVendors([]);
    try {
      const vendor = new Vendor();
      const data = await vendor.getStateVendors(state);
      if (data) {
        setAvailableVendors(data.vendors);
      }
      if (data.vendors.length <= 0) {
        setStateVendor(1);
      } else if (data.vendors.length >= 1) {
        setStateVendor(2);
      }
    } catch (e) {
      console.log(e);
    } finally {

    }
  };

  const getOrderType = async () => {
    try {
      const vendor = new Vendor();
      const response = await vendor.getOrderType();
      setOrderType(response.vendorOrderTypes);
    } catch (e) {
     handleAlert(e, 'info')
    } finally {}
  };

  const getFeeSchedules = async (id) => {
    try {
      const vendor = new Vendor();
      const response = await vendor.getFeeSchedules(id);
      setFeeSchedules(response?.vendorFeeScheduleAndOrderParts?.feeSchedules);
    } catch (e) {
     handleAlert(e, 'info')
    } finally {}
  };

  const addFeeSchedules = async (addFee) => {
    try {
      const vendor = new Vendor();
      const response = await vendor.addFeeSchedules(addFee);
      if(response.errors){
        handleResponseAlert(response, response?.errors[0]?.detail)
      } else {
        handleResponseAlert(response, `Fee schedule has been added`)
        getFeeSchedules(addFee.userid);
      }
    } catch (e) {
      handleAlert(e, 'info')
    } finally {}
  };


  const updatreFeeSchedules = async (addFee) => {
    try {
      const vendor = new Vendor();
      const response = await vendor.updatreFeeSchedules(addFee);
      if(response.errors){
        handleResponseAlert(response, response?.errors[0]?.detail)
      } else {
        handleResponseAlert(response, `Fee schedule has been updated`)
        getFeeSchedules(addFee.userid);
      }
    } catch (e) {
      handleAlert(e, 'info')
    } finally {}
  };


  const deleteFeeSchedules = async (userid, orderTypeId) => {
    try {
      const vendor = new Vendor();
      const response = await vendor.deleteFeeSchedules(userid, orderTypeId);
      if(response.status === 'fail'){
        handleResponseAlert(response, response.error)
      } else {
        handleResponseAlert(response, `Fee schedule has been deleted`)
        getFeeSchedules(userid);
      }
    } catch (e) {
      handleAlert(e, 'info')
    } finally {}
  };

  const getVendorDetail = async (vendorId) => {
    let searchParam;
    searchParam = new URLSearchParams({'id': vendorId});
    navigate('admin/vendors?' + searchParam.toString());
    try {
      getFeeSchedules(vendorId);
      const vendor = new Vendor();
      setVendorDetailsLoading(true)
      const data = await vendor.getVendorDetail(vendorId);
      if (data.vendor) {
        setVendorDetail(data.vendor);
        const stateDBA = await vendor.fetchStateDBA({vendor_id: vendorId});
        setFormValue({
          ...formValue,
          ...data.vendor,
          dbalist: stateDBA.dbaManagements || []
        })
      } else {
        handleAlert(data.errors[0].detail, 'error')
      }
    } catch (e) {

    } finally {
      setVendorDetailsLoading(false)
    }
  };

  const getAllStates = async () => {
    try {

      const vendor = new Vendor();
      const data = await vendor.getAllStates();
      if (data) {
        setAllStates(data.states);
      }
    } catch (e) {
      console.log(e);
    } finally {

    }
  };

  // const getVendorOrder = async () => {
  //   try {
  //     const vendor = new Vendor();
  //     const data = await vendor.getVendorOrder();
  //     console.log('get vendor order:', data)
  //   } catch (e) {
  //     console.log(e);
  //   } finally {

  //   }
  // };


  const getVendorsBy = async (keyword, state, type) => {
    try {

      const vendor = new Vendor();
      setVendorLoading(true);
      const data = await vendor.getVendorsBy(keyword, state, type);
      if (data) {
        setAvailableVendors(data.vendors);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setVendorLoading(false)
    }
  }

  const getVendorType = async () => {
    try {

      const vendor = new Vendor();
      const data = await vendor.getVendorType();
      if (data) {
        setVendorType(data.vendors);
      }
    } catch (e) {
      console.log(e);
    } finally {

    }
  };

  const addVendor = async (payload) => {

    try {
      setAddVendorBtnLoading(true);
      const vendor = new Vendor();
      const response = await vendor.addVendor(payload);
      if(response.vendorid) {
        getVendorDetail(response.vendorid)
      }

    } catch (e) {
      console.log(e);
      handleAlert(e, 'error')
    } finally {
      setAddVendorBtnLoading(false);
    }
  };

  const enableVendor = async (vendorid, exclude) => {
    try {
      setAssignVendorLoading(true);
      const vendor = new Vendor();
      const response = await vendor.enableVendor(vendorid, exclude);
      setvendorApprove(response)
    } catch (e) {
      console.log('enableVendor error', e);
      setAssignVendorLoading(false);
    } finally {
      setAssignVendorLoading(false);
    }
  };

  const activeVendor = async (vendorid, active) => {
    try {
      const vendor = new Vendor();
      await vendor.activeVendor(vendorid, active);
    } catch (e) {
    } finally {}
  };


  const saveUpdateVendorProfilePicture = async (userid, profile) => {
    try {
      const vendor = new Vendor();
      const response = await vendor.saveUpdateVendorProfilePicture(userid, profile);
      if(response.status === "success") {
        setFormValue({...formValue, profile_picture: response?.picture})
        getVendorDetail(userid);
        handleResponseAlert(response, `Profile picture has been updated`)
      }

    } catch (e) {
      handleAlert(e, 'info')
    } finally {}
  };

  const deleteVendorProfilePicture = async (userid) => {
    try {
      const vendor = new Vendor();
      const response = await vendor.deleteVendorProfilePicture(userid);
      if(response.status === 'success'){
        setFormValue({...formValue, profile_picture: null})
        getVendorDetail(userid);
        handleResponseAlert(response, `Profile picture has been deleted`)
      }

    } catch (e) {
      handleAlert(e, 'info')
    } finally {}
  };


  const saveUpdateVendorFirmLogo = async (userid, profile) => {
    try {
      const vendor = new Vendor();
      const response = await vendor.saveUpdateVendorFirmLogo(userid, profile);
      console.log(response.status);
      if(response.status === "success") {
        setFormValue({...formValue, firm_logo: response?.logo});
        getVendorDetail(userid);
        handleResponseAlert(response, `Firm Logo has been updated`)
      }
    } catch (e) {
      handleAlert(e, 'info')
    } finally {}
  };

  const deleteVendorFirmLogo = async (userid) => {
    try {
      const vendor = new Vendor();
      const response = await vendor.deleteVendorFirmLogo(userid);
      if(response.status === 'success'){
        setFormValue({...formValue, firm_logo: null});
        getVendorDetail(userid);
        handleResponseAlert(response, `Firm Logo has been deleted`)
      }

    } catch (e) {
      handleAlert(e, 'info')
    } finally {}
  };


  const saveUpdateVendorFirmSignature = async (userid, profile) => {
    try {
      const vendor = new Vendor();
      const response = await vendor.saveUpdateVendorFirmSignature(userid, profile);
      if(response.status === "success") {
        setFormValue({...formValue, firm_signature: response?.signature});
        getVendorDetail(userid);
        handleResponseAlert(response, `Firm Signature has been updated`)
      }
    } catch (e) {
      handleAlert(e,'info')
    } finally {}
  };

  const deleteVendorFirmSignature = async (userid) => {
      console.log(userid);
    try {
      const vendor = new Vendor();
      const response = await vendor.deleteVendorFirmSignature(userid);
      if(response.status === 'success'){
        setFormValue({...formValue, firm_signature: null});
        getVendorDetail(userid);
        handleResponseAlert(response, `Firm Signature has been deleted`)
      }
    } catch (e) {
      handleAlert(e, 'info')
    } finally {}
  };

  const searchVenderByKey = async (word) => {
    try {
      const vendor = new Vendor();
      const data = await vendor.searchVenderByKey(word);
      if (data) {
        setAvailableVendors(data.vendors);
      }
      if (data.vendors.length <= 0) {
        setStateVendor(1);
      } else if (data.vendors.length >= 1) {
        setStateVendor(2);
      }
    } catch (e) {
      console.log(e);
    } finally {

    }
  };

  const deleteEmployee = async (userid, employeeid) => {
    try {
      const vendor = new Vendor();
      const response = await vendor.deleteEmployee(userid, employeeid);
      if(response.status === 'success'){
        getVendorDetail(userid);
        handleResponseAlert(response, `Employee has been deleted`)
      }
    } catch (e) {
     handleAlert(e, 'info')
    } finally {}
  };

  const saveEmployee = async (payload) => {
    try {
      const vendor = new Vendor();
      const response = await vendor.saveEmployee(payload);
      if(response.status === 'success'){
        getVendorDetail(payload?.parent_user);
        handleResponseAlert(response, `Employee has been added`)
      }
    } catch (e) {
      handleAlert(e, 'info')
    } finally {}
  };


  const getEmpolyeeProfile = async (id) => {
     try {
      const vendor = new Vendor();
      const data = await vendor.getVendorDetail(id);
      const profile = data?.vendor;
      setProfileData({
        "email": profile?.email,
        "firstname": profile?.firstname,
        "lastname": profile?.lastname,
        "company_name": profile?.company_name,
        "phone": profile?.phone,
        "mobile": profile?.mobile,
        "password": profile?.password,
        "certificates" : profile?.certificates,
        "license_status" : profile?.license_status
      });
      setVendorProfile(true);
     } catch (e) {
      console.log(e);
    } finally {}
  }


  const getVendorProfile = async () => {
    try {
      const vendor = new Vendor();
      const data = await vendor.getVendorProfile();
      const profile = data?.vendor;
        setProfileData({
        "email": profile?.email,
        "firstname": profile?.firstname,
        "lastname": profile?.lastname,
        "company_name": profile?.company_name,
        "phone": profile?.phone,
        "mobile": profile?.mobile,
        "password": profile?.password,
        "certificates" : profile?.certificates,
        "license_status" : profile?.license_status
      });
    } catch (e) {
      console.log(e);
    } finally {}
  };

  const addVendorProfile = async (payload) => {
    try {
      const vendor = new Vendor();
      const response = await vendor.addVendorProfile(payload);

      if(response.status === 'success'){
        handleResponseAlert(response, `Vendor has been added`)
      }
    } catch (e) {
      handleAlert(e, 'info')
    } finally {}
  };

  const updateVendorProfile = async (payload) => {
    try {
      const vendor = new Vendor();
      const response = await vendor.updateVendorProfile(payload);
      if(response.status === 'success'){
        handleResponseAlert(response, `Employee has been updated`)
      }
    } catch (e) {
      handleAlert(e, 'info')
    } finally {}
  };

  const fetchStateDBA = async (payload) => {
    try {
      const vendor = new Vendor();
      const response = await vendor.fetchStateDBA(payload);
      if(response.status === 'success'){
        setFormValue({
          ...formValue,
          dbalist: response.dbaManagements
        })
      }
    } catch (e) {
      handleAlert(e, 'info')
    } finally {}
  };

  const updateStateDBA = async (payload, new_dba=false) => {
    try {
      const vendor = new Vendor();
      const response = await vendor.updateStateDBA(payload);

      if(response.status === 'success'){
        if (new_dba) await fetchStateDBA(payload);
        handleResponseAlert(response, `DBA has been updated`)
      } else {
        await fetchStateDBA(payload);
      }
    } catch (e) {
      handleAlert(e, 'info')
    } finally {}
  };

  const deleteStateDBA = async (payload) => {
    try {
      const vendor = new Vendor();
      const response = await vendor.deleteStateDBA(payload);

      if(response.status === 'success'){
        handleResponseAlert(response, `DBA has been deleted`)
      } else {
        await fetchStateDBA(payload);
      }
    } catch (e) {
      handleAlert(e, 'info')
    }
  }

  const updateVendorCertificate = async (payload) => {
    try {
      const vendor = new Vendor();
      const response = await vendor.updateVendorCertificate(payload);

      if(response.status === 'success'){

        if(payload[0].new) {
          setFormValue({
            ...formValue,
            certificates: response.certificates || formValue.certificates
          })
        }

        handleResponseAlert(response, `Certificated has been updated`)
      }
    } catch (e) {
      handleAlert(e, 'info')
    } finally {}
  };
  
  const uploadVendorProfileDocument = async (payload) => {
    try
    {
      const vendor = new Vendor();
      
      if( typeof payload.length != "number")
      {
        //its not an array turn into array of 1 object
        payload = [ payload ];
      }
      
      for ( const item of payload)
      {
        await vendor.uploadVendorDocument(item.document,item.documentType,item.licensestate);
      }

    }
    catch (e)
    {
      
    }
  };

  const updateVendorField = async (payload) => {
    try {
      const vendor = new Vendor();
      const response = await vendor.updateVendorField(payload);
      handleResponseAlert(response, `${payload.fieldname} field updated successfully`)
    } catch (e) {
      handleAlert(e, 'info');
    } finally {}
  };


  // const getOrderFeeSchedules = async (id) => {
  //   try {
  //     const vendor = new Vendor();
  //     const response = await vendor.getOrderFeeSchedules(id);
  //     // setFeeSchedules(response?.vendorFeeScheduleAndOrderParts?.feeSchedules);
  //   } catch (e) {
  //   handleAlert(e, 'info')
  //   } finally {}
  // };




  React.useEffect(() => {
    getVendorType();
    getAllStates();
    getOrderType();
    // getVendorProfile();
    // getVendorOrder();
  }, []);

  const memoedValue = React.useMemo(
    () => ({
      getStateVendors,
      stateVendor,
      addVendorLoading,
      addVendorBtnLoading,
      assignVendorLoading,
      availabeVendors,
      selectedVendor,
      setSelectedVendor,
      states,
      vendorType,
      vendorDetail,
      getVendorDetail,
      addVendor,
      enableVendor,
      activeVendor,
      deleteVendorProfilePicture,
      saveUpdateVendorProfilePicture,
      saveUpdateVendorFirmLogo,
      deleteVendorFirmLogo,
      saveUpdateVendorFirmSignature,
      deleteVendorFirmSignature,
      searchVenderByKey,
      deleteEmployee,
      saveEmployee,
      formValue,
      setFormValue,
      setAddVendorBtn,
      addVendorBtn,
      setTabValue,
      tabValue,
      searchStateVendors,
      vendorSearchResults,
      vendorApprove,
      setAvailableVendors,
      setVendorProfile,
      vendorProfile,
      getVendorProfile,
      setProfileData,
      profileData,
      feeSchedules,
      deleteFeeSchedules,
      addFeeSchedules,
      updatreFeeSchedules,
      updateVendorProfile,
      deleteLicenceField,
      addLicenceField,
      updateVendorField,
      fetchStateDBA,
      updateStateDBA,
      deleteStateDBA,
      updateVendorCertificate,
      deleteVendorLicense,
      getEmpolyeeProfile,
      handleAlert,
      getVendorsBy,
      vendorLoading,
      vendorDetailsLoading,
      orderType,
      feeOpen, 
 	    setFeeModalOpen,
      uploadVendorProfileDocument,
      uploadVendorCertificate,
      downloadVendorCertificate,
      downloadVendorInsurance
    }),
    [availabeVendors, selectedVendor, states, stateVendor,
    addVendorLoading, addVendorBtnLoading, vendorType,
    vendorDetail, formValue, setFormValue, assignVendorLoading,
    addVendorBtn, tabValue, vendorSearchResults, vendorApprove,
    vendorProfile, setProfileData, profileData, feeSchedules,
    updateVendorField, updateVendorCertificate, deleteVendorLicense,
    fetchStateDBA, updateStateDBA, deleteStateDBA,
    orderType, setFeeModalOpen]
  );

  return (
    <VendorContext.Provider value={memoedValue}>
      {children}
    </VendorContext.Provider>
  );
});

export function useVendor() {
  return React.useContext(VendorContext);
}
