import { PDFView } from "./PDFView"
import { PDFText } from "./PDFText"

export const PDFLabeleedText = ({ label, value, border, labelWidth='w-60', valueWidth='w-40' }) => {
  return (
    <PDFView className="flex">
      <PDFView className={`${labelWidth} ${border? 'border p-4-8': ''}`}>
        <PDFText
          className={`black ${border? '': 'bold'}`}
          value={label}
        />
      </PDFView>
      <PDFView className={`${valueWidth} ${border? 'border p-4-8': ''}`}>
        <PDFText
          value={value}
        />
      </PDFView>
    </PDFView>
  )
}
