import { BaseModel } from 'core/models/base'

export class Company extends BaseModel {
  company
  address
  address_2
  city
  state
  zip
  tech_phone
  cs_phone
  email

  /** @returns {Company} */
  static fromJSON(json) { return super.fromJSON(json) }
}
