import React from 'react'
import ReactCodeInput from 'react-code-input'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import './styles.scss'

export function CodeInputModal({
    show = true,
    isMFAFailed = false,
    phoneLastFour = '1231',
    onClose = () => { },
    onSubmit = (code) => { },
    resendMFACode = () => { }
}) {
    const [pinCode, setPinCode] = React.useState("")
    const [isGetNewOne, setGetNewOne] = React.useState(false)

    const onChangeCodeInput = (code) => {
        setPinCode(code)
    }

    const onClickResend = () => {
        resendMFACode();
        setGetNewOne(true)
    }

    const submitNewCode = () => {
        setGetNewOne(false)
        onSubmit(pinCode)
    }

    const inputStyleInvalid = {
        border: '1px solid red',
        margin: '4px',
        boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 10px 0px'
    }
    return (
        <Dialog
            open={show}
            className='token-code'
        >
			<div className='modal'>
            <div className='body'>
                <h4 className='h4'>Personal token code</h4>
                <p className='p'>Please enter the 6 digit security code we have sent to your mobile phone * *** **** {phoneLastFour}.</p>
                <Box className='code-input'>
                    <ReactCodeInput
                        name='token-code-input'
                        inputMode='numeric'
                        type='text'
                        fields={6}
                        onChange={onChangeCodeInput}
                        required
                        isValid={!isMFAFailed}
                        inputStyleInvalid = {inputStyleInvalid}
                    />
                    {isMFAFailed && !isGetNewOne  && <div className='auth-failed'>The code is incorrect</div>}
                </Box>
                <Box>
                    <Button className='input-submit-btn' onClick={() => {submitNewCode()}}>Submit</Button>
                </Box>
            </div>
            <div className='footer'>
                <div className='reminder'>
                    Did not receive the code?
                    <Button className='btn-link' onClick={() => {onClickResend()}}>Get a new one</Button>
                    {isGetNewOne && <div className='get-new-one'>MFA code has been resent.</div>}
                </div>
            </div>
			</div>
        </Dialog>
    )
}

