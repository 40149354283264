import { LightCycleApi, Api } from 'core/api'
import { BaseModel } from 'core/models/base'
import { DocQuestion } from 'core/models/document/question'
import { DocSource } from 'core/models/document/source'
import { DocSection } from 'core/models/document/section'

import sampleResponse from 'tests/sample.json'
import signatureResponse from 'tests/signature.json'
import sidebarResponse  from 'tests/sidebar.json'


export class Document extends BaseModel {
  tx_id
  question
  sources
  sidebar

  /** @returns {Document} */
  static fromJSON(json) {
    const result = super.fromJSON(json)
    if (result) {
      result.question = DocQuestion.fromJSONArray([json?.question])
      result.sources = DocSource.fromJSONArray(json?.sources)
    }
    return result
  }

   static async getSignature() {
    try {
      const result =  await Api.get('/participant/signature')
      return { result: result?.data }
    } catch (error) {
      return { result: null }
    }
  }

  static async load({ txUUID }) {
    // const { error, data } = await LightCycleApi.request({
    // 	method: LightCycleApi.Method.LoadDocument,
    // 	params: {
    // 		"tx_uuid": txUUID,
    // 	}
    // })
    // return { error, result: Document.fromJSON(data?.response) }

    try{
      const result = await Api.get('/document/'+txUUID)
      return { result:  Document.fromJSON(result?.response) }
    }catch(error){
      return{ error }
    }
    // return { result: Document.fromJSON(sampleResponse.response) }

  }
  
  static async downloadOrderPartDocument(partid){
    try{
      const result = await Api.downloadFile('/order/download', {partid: partid})
      return {result: result}
    }catch(error){
      return{ error }
    }
  }

  static async downloadSignature(output='png'){
    try{
      const result = await Api.downloadFile('/participant/export-signature?output=' + output)
      return {result: result}
    }catch(error){
      return{ error }
    }
  }

  static async uploadOrderDocument(file, orderId, description){
    try{
        const params = [
          {name: 'file', value: file},
          {name: 'orderid', value: orderId},
          {name: 'description', value: description}
        ]
        const result = await Api.uploadFiles('/participant/document', params)
        return {result: result}
      } catch (error) {
        return(error)
      }
  }

  static async uploadSignature(file){
    try{
        const params = [
          {name: 'signature', value: file}
        ]
        const result = await Api.uploadFiles('/participant/signature', params)
        return {result: result}
      } catch (error) {
        return {result: {status: 'failed', message: error}}
      }
  }

  static async UpdateOrderParticipantQuestion(partid, element, inviteUUID){
    try{
      let params;
      if (partid) 
        params = [
          {name: 'partid', value: partid},
          {name: 'form_element', value: element.element_name},
          {name: 'form_value', value: element.value}
        ]
      else 
        params = [
          {name: 'invite_uuid', value: inviteUUID},
          {name: 'form_element', value: element.element_name},
          {name: 'form_value', value: element.value}
        ]
      const result = await Api.post('/participant/question', params, "FORM")
      element.status = result.status
      if (result.status == 'updated')
        element.user_value = element.value

      return {result: result}
    } catch (error) {
      return(error)
    }
  }

  static async getOrderInfo(){
    try{
      const result = await Api.get('/order/participant')
      // if (result && result.order) {
      //   const result1 = await Api.get('/order/parts', {orderid: result.order.orderid})
      //   return {result: {order: result.order, parts: result1.parts}}
      // }
      return {result: result}
    } catch(error){
      return {error}
    }
  }

  /** @returns {DocQuestion} */
  calcQuestion(index) {
    if (this.question?.length > index) return this.question[index]
    return null
  }

  /** @returns {DocSection} */
  section(index, questionIdx = 0) {
    return this.calcQuestion(questionIdx)?.section(index)
  }

  // /** @returns {Docsidebar} */
  // calcSidebar(index) {
  //   if (this.sidebar?.length > index) return this.sidebar[index]
  //   return null
  // }
}
