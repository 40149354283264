
import { Route, Navigate, useLocation } from 'react-router-dom';
import { lazy } from 'react';

const VendorPage = lazy(() => import('routes/vendor'));
const VendorRegisterPage = lazy(() => import('pages/vendor/register'));

export default function vendorRoutes({ canHas }) {
  if (canHas('VENDOR')) {
    return (
      <Route path="vendor/*" element={<VendorPage />} />
    );
  }
  const location = useLocation()
  if (location.pathname == "/vendor/register") {
    return (
      <Route path="vendor/register" element={<VendorRegisterPage />} />
    );
  }
  
  return (
    <Route path="vendor/*" element={<Navigate to="/login" replace />} />
  );
}

