import React from 'react';
import { styled } from "@mui/material";
import Typography from '@mui/material/Typography';
import lightCycle from '../../assets/images/lightCycle.png';
import { css } from "@mui/system";
import "./styles.scss"
import CompanyLogo from 'common/ui/CompanyLogo';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

const SplashTitle = styled('div')(css`
    font-weight: 900;
    font-size: 50px;
    display: inline-block;
    text-align : right;
    width: 100%;
    margin-top: 48px;
    span{
      display: inline-block;
      background:linear-gradient(to right,#65a8e7,#88ddd5,#1ab9e9,#b4bf97,#a9989d,#b77a97,#ecb09e,#c77fa8,#8e64ae);
      -webkit-background-clip:text;
      -webkit-text-fill-color:transparent;
      margin-right: 50px;
      font-weight: 100;
      text-align : center;
      margin: 0 auto;
    }
    small{
      text-align: right;
      letter-spacing: -1px;
      font-size: 14px;
      display: block;
      font-weight: 500;
      padding: 0px 5px;
      margin-top: -12px;
    }
`);

const SplashBody = styled('div')(({ theme }) => (css`
    text-align: left;
    h4 {
      text-align: left;
      margin-top: 36px;
      font-weight: 700;
      font-size: 1.5rem;
    }
    p{
      margin-top: 4px;
      margin-bottom: 25px;
      font-size: 15px;
    }
` ), );

const SplashFooter = styled('div')(css`
    background-color: #f1f2f7;
    text-align: center;
    padding-top: 12px;
    .MuiTypography-body1{
      margin: 2px;
      font-weight: 500;
      font-size: .4rem;
    }
`);

const SplashButton  = styled('div')(css`
  border-top: 2px solid #f0f0f0;
  border-bottom: 2px solid #e0e0e0;
  padding: 18px 36px;
  margin-top: 24px;
  button{
    padding: 12px;
    width: 100%;
    font-size: 16px;
  }
`);

const SplashMain = styled('div')(css`
    padding: 0 36px;
`);

export function LandingSplashModal({
    show = true,
    onClose = () => { },
    onClickStartAuth = () => {}
}) {
  const [open, setOpen] = React.useState(false);
  return (
    <Dialog
      open={show} className='splash-modal'>
      <SplashMain>
        <SplashTitle>
          <CompanyLogo/>
        </SplashTitle>
        <SplashBody>
          <Typography variant="h4">
            Welcome to Voxtur
          </Typography>

          <Typography>
            Congratulations on your recent real estate
            transaction. As part of the Title insurance
            application process, your lender has requested
            that you complete the Seller Statement of
            Information form to expedite your process
          </Typography>

          <Typography>
            We will guide you through an easy step-by-step
            process that verifies who you are and some
            important information about your property.
          </Typography>
        </SplashBody>
      </SplashMain>

      <SplashButton>
        <Button variant="contained" color="primary" onClick={onClickStartAuth}> LET'S GET STARTED</Button>
      </SplashButton>
        <SplashFooter>
          <img src={lightCycle}/>
          <Typography variant="body1">Secured and authenticated by LightCycle California Inc ™</Typography>
        </SplashFooter>
    </Dialog>
  )
}

