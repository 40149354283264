import React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';

export default function LCheckbox({
  className = "",
  element

}) {
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() =>{
    if(element.value !== undefined)
      setChecked(element.value);
    else if (element.user_value == "true")
      setChecked(true)
  })
  const handleChange = (event) => {
    setChecked(event.target.checked);
    element.value = event.target.checked
  };
  return (
    <Grid container spacing={1} className="grid-container">
      {element.label !== "" &&
        <Grid xs={12} item={true}>
          <FormControlLabel
            className='form-checkbox'
            label={element.label}
            checked={checked == true}
            onChange={handleChange}
          control={element.error ? 
              <Checkbox sx={{
                color: 'red !important',
              '&.Mui-checked': {
                  color: 'red !important',
              },
              }}/> 
              :
              <Checkbox color="primary"/>}
          />
        </Grid>
      }
    </Grid>
  )
}

