import React from 'react';
import { TextField } from '@material-ui/core';
import InputMask from "react-input-mask";
import Grid from '@mui/material/Grid';

export function SocialNumber({
  className = "",
  element,
}) {
  const [value, setValue] = React.useState('');
  React.useEffect(() => {
    if (element.value !== undefined)
      setValue(element.value)
    else if (element.user_value)
      setValue(element.user_value)
  }, [])

  const handleChange = event => {
    setValue(event.target.value);
    element.value = event.target.value
  };

  let placeholder = '';
  if (element.verification.verification_source.length > 0)
    placeholder = element.verification.verification_source[0].value[0];
  return (
    <>
      {element.label !== "" &&
        <Grid container spacing={1} className="grid-container">
          <Grid xs={12} md={7} item={true}>
            <label dangerouslySetInnerHTML={{ __html: element.label }}/>
            <label className='required'>{element.required && '*'}</label>
          </Grid>
          <Grid xs={12} md={5} item={true} >
            <InputMask
              mask="999-99-9999"
              value={value}
              disabled={false}
              maskChar="_"
              onChange={handleChange}
            >
              {() => <TextField 
                error={element.error}
                className="full-width"
                variant="outlined"
                helperText={element.error_message}
              />}
            </InputMask>
          </Grid>
        </Grid>
      }
    </>
  );
}