
import { Route, Navigate } from 'react-router-dom';
import { lazy } from 'react';

const AdminPage = lazy(() => import('routes/admin'));

export default function adminRoutes ({ canHas }) {
  if (canHas('ADMIN')) {
    return (
      <Route path="admin/*" element={<AdminPage />} />
    );
  }

  return (
    <Route path="admin/*" element={<Navigate to="/login" replace />} />
  );
}

