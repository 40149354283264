import _ from 'lodash'

function is(val) {
  return val !== null && typeof val === 'object'
}

function isValid(val, property = null) {
  if (val === null || val === undefined) return false
  if (!isValid(property)) return true
  return isValid(value(val, property))
}


function value(val, path = null) {
  if (!isValid(val)) return null
  if (!path) return val

  if (!path.includes('.')) return val[path]

  const curPath = path.split('.')[0]
  if (curPath === path) return val[path]
  return value(val[curPath], path.replace(`${curPath}.`, ''))
}

function copy(val) {
  if (val === null || val === undefined) return null
  return _.cloneDeep(val)
}

function merge(a, b) { return _.cloneDeep(_.merge(a, b)) }

export const ObjectHelper = {
  is, isValid, value,
  copy, merge,
}
