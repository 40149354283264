
import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#005fd4',
    },
    secondary: {
      main: '#0078d3',
    },
    error: {
      main: '#ff1241',
    },
    warning: {
      main: '#fbb101',
    },
    info: {
      main: '#36c1f0',
    },
    success: {
      main: '#1ed400',
    },
    selected: {
      main: '#2e7d32',
    },
    background: {
      default: '#f7f7fd',
    },
  },
  typography: {
    fontFamily: [
      'Inter',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    code: {
      fontFamily: 'monospace',
      fontSize: '1rem',
      fontWeight: 500,
      color: grey[200],
      backgroundColor: grey[600],
      padding: '1ch',
      margin: '1em 0',
      display: 'block',
      wordBreak: 'break-word',
    },
    h1: {
      fontSize: 36,
      fontWeight: 500,
    },
    h2: {
      fontSize: 30,
      fontWeight: 500,
    },
    h3: {
      fontSize: 24,
      fontWeight: 500,
    },
    h4: {
      fontSize: 20,
      fontWeight: 500,
    },
    h5: {
      fontSize: 18,
      fontWeight: 500,
    },
    h6: {
      fontSize: 16,
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 16,
      color: '#7c7d96',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minHeight: '100vh',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          fontSize: '1rem',
          padding: '15px 40px',
          color: '#ffffff',
        },
      },
    },
  },
});

export default theme;
