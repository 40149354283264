import React from 'react';
import { TextField } from '@material-ui/core';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Grid from '@mui/material/Grid';

export function DateField ({
  className = "",
  element
}) {
  const [date, setDate] = React.useState(null);
  React.useEffect(() =>{
    if(element.value !== undefined)
      setDate(element.value);
    else if (element.user_value)
      setDate(element.user_value)
  },[])

  const handleChange = (newValue) => {
    if (newValue) {
      var date = new Date(newValue)
      var finaldate = (date.getMonth() + 1).toString().padStart(2, "0") + '/'  + date.getDate().toString().padStart(2, "0") + '/' +  date.getFullYear()
      setDate(finaldate);
      element.value = finaldate;
    } else {
      setDate("");
      element.value = "";
    }
  };

  return (
    <>
      {element.label !== "" &&
        <Grid container spacing={1} className="grid-container">
          <Grid xs={12} md={7} item={true}>
            <label dangerouslySetInnerHTML={{ __html: element.label }}/>
            <label className='required'>{element.required && '*'}</label>
          </Grid>
          <Grid xs={12} md={5} item={true} >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={date}
                inputFormat="MM/dd/yyyy"
                onChange={handleChange}
                renderInput={(params) => <TextField className="full-width" variant="outlined" {...params} autoComplete='off' error={element.error}/>}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      }
    </>
  );
}
