import AbstractGC from './abstract';

export default class NewOrder extends AbstractGC {

  async clients() {
    return await this.get('neworder/clients');
  }

  async users(clientId) {
    return await this.get(`neworder/users/${clientId}`);
  }

  async productList(userId) {
    return await this.get(`neworder/order-types/${userId}`)
  }

  async participantList() {
    return await this.get(`neworder/participants-role`)
  }

  async entityType() {
    return await this.get(`neworder/entity-types`)
  }

  async entityState() {
    return await this.get(`states`)
  }

  async addNewOrder(payload) {
    return await this.post(`neworder`, payload);
  }

  async getMapKey() {
    return await this.get(`neworder/map-keys`)
  }

  async verifiyAddress(payload) {
    return await this.post(`neworder/validate-address`, payload)
  }

  async zipcodeLookup(zip) {
    return await this.get(`zipcodelookup/${zip}`)
  }

}
