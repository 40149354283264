import moment from 'moment-timezone'

import { Storage } from 'utils/storage'

export class DateHelper {
  static get default() { return Storage.timezone }
  static set default(value) { Storage.timezone = value || moment.tz.guess(true) }

  static moment(date) {
    return moment(date).tz(DateHelper.default, false)
  }

  static parse(value, formatter = null, timezone = null) {
    if (!value) return null

    let result = null
    const tz = timezone || DateHelper.default

    if (formatter) result = moment.tz(value, formatter, tz)
    else result = moment(value).tz(tz, false)

    return result?.toDate()
  }

  static format(date, format = 'YYYY-MM-DD hh:mm A') {
    if (!date) { return '' }

    return moment(date).tz(DateHelper.default, false).format(format)
  }

  // Compare
  static isSame(a, b, formatter = 'YYYY-MM-DD hh:mm A') {
    if (a === b) return true
    if (!a || !b) { return false }
    return DateHelper.format(a, formatter) === DateHelper.format(b, formatter)
  }

  static isSameDay(a, b) { return DateHelper.isSame(a, b, 'YYYY-MM-DD') }
  static isSameMonth(a, b) { return DateHelper.isSame(a, b, 'YYYY-MM') }
  static isSameYear(a, b) { return DateHelper.isSame(a, b, 'YYYY') }

  // Get
  static getDayOfYear(date = new Date()) {
    return parseInt(DateHelper.format(date, 'DDD DDDD'))
  }

  static getHour(date = new Date()) {
    const result = DateHelper.format(date, 'HH')
    return parseInt(result)
  }

  static getMinute(date = new Date()) {
    const result = DateHelper.format(date, 'mm')
    return parseInt(result)
  }

  static getAmPm(date = new Date()) {
    const result = DateHelper.format(date, 'a')
    return result
  }

  // Calculation
  static startOf(date = new Date(), params) { return moment(date).tz(DateHelper.default, false).startOf(params).toDate() }
  static startOfDay(date) { return moment(date).tz(DateHelper.default, false).hours(0).minutes(0).seconds(0).toDate() }
  static startOfWeek(date) { return DateHelper.startOf(date, 'week') }
  static startOfMonth(date) { return DateHelper.startOf(date, 'month') }

  static endOf(date, unit) { return moment(date).tz(DateHelper.default, false).endOf(unit).toDate() }
  static endOfDay(date) { return DateHelper.endOf(date, 'day') }
  static endOfMonth(date) { return DateHelper.endOf(date, 'month') }

  static add(date, value, unit) { return moment(date).tz(DateHelper.default, false).add(value, unit).toDate() }
  static addHour(date = new Date(), hours) { return DateHelper.add(date, hours, 'hours') }
  static addMinute(date = new Date(), minutes) { return DateHelper.add(date, minutes, 'minutes') }
  static addSeconds(date = new Date(), minutes) { return DateHelper.add(date, minutes, 'seconds') }

  static addDay(date = new Date(), days) { return DateHelper.add(date, days, 'days') }
  static addMonth(date = new Date(), months) { return DateHelper.add(date, months, 'months') }
  static addYear(date = new Date(), years) { return DateHelper.add(date, years, 'years') }

  // Compare
  static min(a, b) { return moment.min([DateHelper.moment(a), DateHelper.moment(b)]).toDate() }
  static max(a, b) { return moment.max([DateHelper.moment(a), DateHelper.moment(b)]).toDate() }

  // Timezone
  static isDSTTimezone(timezone = DateHelper.default) { return moment(new Date()).tz(timezone, false).isDST() }
  static isDST(date = Date.now()) { return moment(date).tz(DateHelper.default, false).isDST() }
  static applyTimezone(date, timezone = null) {
    if (!date) return null

    const tz = timezone || DateHelper.default

    return moment(date).tz(tz, false).toDate()
  }
}
