
import { useState,useRef, useEffect } from 'react';
import { Link, useNavigate, Navigate, Route , useLocation , useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import useAuthentication from 'common/authentication';
import useLocalStorage, { useSessionStorage } from '@twipped/hooks/useLocalStorage';
import { catcher } from '@twipped/utils';
import useCompany from '../common/company';
import { OktaAuth } from '@okta/okta-auth-js';

export default function ExternalLogin () {

  const { settings } = useCompany();
  const { createSessionFromExternal, logout } = useAuthentication();
  const [searchParams] = useSearchParams();
  const [ oktatoken , setOktatoken ] = useSessionStorage('okta');
  const [ localJWT, setLocalJWT ] = useLocalStorage('jwt');
  const [ sessionJWT, setSessionJWT, ] = useSessionStorage('jwt');


  const location = useLocation();
  const navigate = useNavigate();
  
  const oktaAuth = new OktaAuth({
    issuer: 'https://'+settings.oktaDomain+'.okta.com/oauth2/default',
    clientId: settings.oktaClientId,
    redirectUri: window.location.origin + '/r/externallogin/callback',
    postLogoutRedirectUri : window.location.origin + '/r/login'
  });

  useEffect( async () => {
    
    try{
    
      if (oktaAuth.isLoginRedirect())
      {
        const { tokens } = await oktaAuth.token.parseFromUrl();
        
        oktaAuth.tokenManager.setTokens(tokens);
        
        const user = await oktaAuth.getUser();
        const accesstoken = oktaAuth.getAccessToken();
  
        const { userType } = await createSessionFromExternal(user.email,accesstoken);
  
        if (userType === 1) {
          navigate('/admin');
          return;
        }
        if (userType === 2)
        {
          navigate("/vendor");
          return;
        }
        if (userType === 3) {
          navigate('/client');
          return;
        }
      }
      else if( await oktaAuth.isAuthenticated() )
      {
        const user = await oktaAuth.getUser();
        const accesstoken = oktaAuth.getAccessToken();
        
        const { userType } = await createSessionFromExternal(user.email,accesstoken);
        
        if (userType === 1) {
          navigate('/admin');
          return;
        }
        if (userType === 2)
        {
          navigate("/vendor");
          return;
        }
        if (userType === 3) {
          navigate('/client');
          return;
        }
      }
      else
      {
        var redirectconfig = {
          originalUri: "/"
        };
        
        if(searchParams.get('token'))
        {
          redirectconfig.loginHint = atob(searchParams.get('token'))
        }
  
        oktaAuth.signInWithRedirect(redirectconfig);
      }
    }
    catch (e) {

      console.log('catch');
      console.log(e);

      logout();

      await oktaAuth.signOut();
      setOktatoken(null);
      
      setSessionJWT(null);
      setLocalJWT(null);

    }
  }, [] );

  return null;  

}

