import React from 'react'
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import ArticleIcon from '@mui/icons-material/Article';

export function MediaLink({
  element,
}) {
  return (
    <Grid container spacing={1} className="grid-container">
      <Grid xs={12} item={true}>
        <Link href={element.options[0].option_name} className="media-link">
          <ArticleIcon/>
          <label dangerouslySetInnerHTML={{ __html: element.label }}></label>
        </Link>
      </Grid>
    </Grid>
  )
}
