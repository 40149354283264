
import useAuthentication, { USER_TYPE } from 'common/authentication';
import NotFoundPage from '../../pages/notFound.js';
import LoadingPage from '../../pages/loading.js';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

export default function RequiresAuth ({ requires, children }) {
  const { isAuthenticated, canHas, loading } = useAuthentication();
  const location = useLocation();

  if (!isAuthenticated) return <Navigate to="/login" state={{ from: location }} replace />;

  if (canHas(requires)) {
    return children;
  }

  if (loading) {
    return <LoadingPage />;
  }

  return <NotFoundPage />;
}
RequiresAuth.propTypes = {
  requires: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(USER_TYPE)),
    PropTypes.arrayOf(PropTypes.oneOf(Object.values(USER_TYPE))),
  ]),
};
