import React from 'react';
import { Button, Grid, styled, Typography, List, ListItem, Link, Box } from '@mui/material';
import { css } from "@mui/system";

import '../../assets/styles/_fonts.scss';

const HeaderContainer = styled('div')(({ theme }) => css`
  margin-bottom: 20px;
  padding: 20px 20px 0 20px;
  background-color: #fff;
  height: 130px;

  .logo-right-text {
    text-align: right;
    color: ${theme.palette.primary.main};
    font-weight: 500;
  }

  .mobile-menu {
    text-transform: uppercase;
    font-size: .85rem;
    line-height: 1;
    background: transparent;
    border: none;
    color: ${theme.palette.primary.main};
    float: right;
    padding: 7px 15px;
    margin: 0 !important;
    box-shadow: none !important;

    i {
      position: relative;
      top: 1px;
      font-size: 24px;
      left: 5px;
    }
  }

  .mobile-top{
    margin-top: 20px
  }
  
  .relative {
    position: relative;
  }

  .steps-button-container {
    width: calc(100% + 40px);
    display: inline-block;
    margin: 27px -20px 0 -20px;
    position: absolute;
    z-index: 100;
    .left {
      width: 50%;
      display: inline-block;
      padding-left: 12px;
    }
    .right {
      width: 50%;
      display: inline-block;
      padding-right: 12px;
      text-align: right;
    }
  }

  .steps-container {
    display: inline-block;
    margin: 14px -20px 0 -20px;
    width: calc(100% + 40px);
    overflow: hidden;
    position: relative;
    top: 6px;
    &:before{
      content: "";
      background-color: #fcfcfe;
      border-top: 1px #ededf4 solid;
      border-bottom: 1px #e2e2ea solid;
      width: 100%;
      height: 43px;
      display: block;
      position: sticky;
      right: 0;
      left: 0;
    }

    .steps {
      display: inline-flex;
      padding: 0;
      width: 100%;
      text-align: center;
      margin-bottom: 0;
      position: absolute;
      top: 0;
      margin-top: -3px;

      li {
        list-style: none;
        display: inline-block;
        flex: auto;
        position: relative;

        &:before {
          content: '';
          width: 25px;
          height: 2px;
          background-color: #e8e8f0;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: -10px;
        }

        &:after {
          content: '';
          width: 10px;
          height: 10px;
          background-color: #c5c5d1;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: -2px;
          border-radius: 50px;
          border: 2px #ffffff solid;
        }

        &:last-child {
          &:after, &:before {
            display: none !important;
          }
        }

        &.active {
          a {
            color: ${theme.palette.primary.main};
            font-weight: 500;
            position: relative;

            &:after {
              content: '';
              width: 75%;
              height: 2px;
              background-color: ${theme.palette.primary.main};
              position: absolute;
              bottom: -1px;
              left: 0;
              right: 0;
              margin: auto;
            }

            &:before {
              font-family: "lightcycle" !important;
              font-style: normal !important;
              font-weight: normal !important;
              font-variant: normal !important;
              text-transform: none !important;
              speak: none;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: "";
              position: absolute;
              bottom: -7px;
              left: 0;
              right: 0;
              margin: auto;
              background-color: ${theme.palette.primary.main};
              color: #fff;
              width: 14px;
              height: 14px;
              border-radius: 50px;
              line-height: 1.2;
              z-index: 1;
              font-size: 13px;
              text-align: center;
              padding: 0;
            }
          }
        }

        &.done {
          &:after {
            background-color: ${theme.palette.success.main} !important;
          }

          a {
            color: #1c1f46;
            font-weight: 500;
            position: relative;

            &:before {
              font-family: "lightcycle" !important;
              font-style: normal !important;
              font-weight: normal !important;
              font-variant: normal !important;
              text-transform: none !important;
              speak: none;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: "\\65";
              background-color: ${theme.palette.success.main};
              color: #fff;
              width: 14px;
              height: 14px;
              border-radius: 50px;
              line-height: 1.8;
              z-index: 1;
              font-size: 8px;
              display: inline-block;
              position: relative;
              top: -1px;
              left: -4px;
              text-align: center;
            }
          }
        }

        a {
          color: #7c7d96;
          text-decoration: none;
          padding: 9px 10px;
          display: block;
          font-size: .85rem;
          background: transparent;
          text-transform: capitalize;
          border: none;
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }
      }
    }
  }
`);

export function StepsHeader ( { steps, activeSection, setActiveSection, sectionCount, branding, checkNextStep } ) {
  if (steps.length == 0) return null

  var steps_container = document.querySelector("div.steps-container")
  if (steps_container) {
    var active_step_element = document.querySelector(`#header-step-${activeSection}`)
    if (active_step_element)
      steps_container.scrollLeft = active_step_element.offsetLeft - 80
  }
  
  const onClickSteps = (index) => {
    if (index < activeSection)
      setActiveSection(index)
  }

  const handleNextStep = () => {
    if (checkNextStep())
      setActiveSection(activeSection + 1)
  }

  return (
    <HeaderContainer>
      <Grid container className='mobile-top'>
        <Grid item xs={12} md={6}>
          {branding &&
            <Link href="#home">
              <Box
                sx={{
                  flexGrow: 1,
                  backgroundPosition: 'left',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: `url(/common/companyfiles.php?logo=1&filename=${branding.logoPrimary})`,
                  height: 54,
                }}
              />
            </Link>
          }
        </Grid>
        <Grid item xs={12} md={6}>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className="relative">
          <Typography component="div" className="steps-button-container">
            <Typography component="div" className='left'>
              {activeSection > 0 && <Button variant="contained" size="small" onClick={()=>setActiveSection(activeSection - 1)}>&lsaquo; prev</Button>}
            </Typography>
            <Typography component="div" className='right'>
              {activeSection < sectionCount - 1 && <Button variant="contained" size="small" onClick={handleNextStep}>next &rsaquo;</Button>}
            </Typography>
          </Typography>
          <Typography component="div" className="steps-container">
            <List className="steps">
              {Object.keys(steps).map((key, index) => {
                if(steps[key].label === '')
                  return

                const { path, label } = steps[key]
                return (
                  <ListItem key={index} className={`${index === activeSection && 'active'} ${index < activeSection && 'done'}`} id={`header-step-${index}`}>
                    <Link onClick={() => onClickSteps(index)}>
                      {`${index + 1}/${steps.length} ${label}`}
                    </Link>
                  </ListItem>
                );
              })}
            </List>
          </Typography>
        </Grid>
      </Grid>
    </HeaderContainer>
  );
}
