import { BaseModel } from 'core/models/base'
import { DocSection } from 'core/models/document/section'

export class DocQuestion extends BaseModel {
	sections = []

	/** @returns {DocQuestion} */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		if (result) {
			result.sections = DocSection.fromJSONArray(json?.sections)
		}
		return result
	}

	/** @returns {DocSection} */
	section(index) {
		return this.sections?.find(section => {
			return parseInt(section.id) === index + 1
		})
	}
}
