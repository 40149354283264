import { Api } from 'core/api'
import { errorMessages } from 'config'
import { BaseModel } from 'core/models/base'
import { Session } from 'core/models/session'
import { Company } from 'core/models/company'
import { Transaction } from 'core/models/transaction'

export class User extends BaseModel {
	email
	company_name
	first_name
	last_name
	last_login
	uuid
	address
	address_2
	city
	state
	zip
	phone

	/** @returns {User} */
	static fromJSON(json) { return super.fromJSON(json) }

	static async checkInviteCode({ code }) {
		try {
			const data =  await Api.get('/participant/login/' + code)
			return {data}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async toMFA({ code }) {
		try {
			const data =  await Api.get('/participant/login/' + code + '/skip')
			return {data}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	static async login(params) {
		try {
			const data =  await Api.post('/participant/login', params)
			return {data}
		} catch (error) {
			return { error: Api.parseError(error, errorMessages.NETWORK_ERROR) }
		}
	}

	// static async register({ email, lastFourCell, photo }) {
	// 	try {
	// 		const { error, data } = await LightCycleApi.request({
	// 			apiEndPoint: LightCycleApi.Method.Register,
	// 			params: {
	// 				email,
	// 				last_four_cell: lastFourCell,
	// 				photo
	// 			}
	// 		})

	// 		return { error, session: Session.fromJSON(data?.response) }
	// 	} catch (error) {
	// 		return { error }
	// 	}
	// }

	// static async login({ email, photo, invite, temp_photo_match }) {
	// 	try {
	// 		const { error, data } = await LightCycleApi.request({
	// 			apiEndPoint: LightCycleApi.Method.Login,
	// 			params: {
	// 				invite,
	// 				email,
	// 				temp_photo_match,
	// 				photo
	// 			}
	// 		})

	// 		return {
	// 			error,
	// 			user: User.fromJSON(data?.response?.user),
	// 			session: Session.fromJSON(data?.response?.session),
	// 			company: Company.fromJSON(data?.response?.session),
	// 			transactions: Transaction.fromJSONArray(data?.response?.transactions)
	// 		}
	// 	} catch (error) {
	// 		return { error }
	// 	}
	// }
}
