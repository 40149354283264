import React, { useEffect, useState } from "react";
import NewOrder from "../../common/models/new-order";
import Company from "../../common/models/company";
import { ToastContainer, toast } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import moment from "moment";
const NewOrderContext = React.createContext({});

export function NewOrderProvider({
    children
}) {
    const [showOrderModal, setShowOrderModal] = useState(false);
    const [selectedClient, setSelectedClient] = useState();
    const [clientLists, setClientLists] = useState([]);
    const [userLists, setUserLists] = useState([]);
    const [productLists, setProductLists] = useState([]);
    const [productLoader, setProductLoader] = useState(false);
    const [newOrderDetail, setNewOrderDetail] = React.useState({ participants_type: 'INDIVIDUAL', property_address_type: 'single', participants: [{ contact_same_as_borrower: true }], anticipated_close_date: null });
    const [participantLists, setParticipantLists] = React.useState();
    const [entityType, setEntityType] = React.useState();
    const [entityState, setEntityState] = React.useState();
    const [participantCount, setParticipantCount] = React.useState(1);
    const [mapKey, setMapKey] = useState("");
    const [selectedAddress, setSelectedAddress] = useState();
    const [nextButton, setNextButton] = useState(true);
    const [activeStep, setActiveStep] = React.useState(0);
    const [orderSteps, setOrderSteps] = React.useState([]);
    const [addedOrderId, setAddedOrderId] = React.useState();
    const [mapCenter, setMapCenter] = React.useState({ lat: 40.8974984, lng: -97.6722622 });
    useEffect(() => {
        switch (activeStep) {
            case 0:
                if (newOrderDetail.hasOwnProperty('orderbyid') && (newOrderDetail.hasOwnProperty('propertyaddress') || newOrderDetail.hasOwnProperty('apn')) && newOrderDetail.hasOwnProperty('propertyzipcode')) {
                    setNextButton(false);
                }
                else {
                    setNextButton(true);
                }
                break;
            case 1:
                if (newOrderDetail.hasOwnProperty('invoiceamount') && newOrderDetail.hasOwnProperty('order_type_id')) {
                    setNextButton(false);
                }
                else {
                    setNextButton(true);
                }
                break;
            case 2:
                if (newOrderDetail.hasOwnProperty('participants')) {
                    setNextButton(false);
                }
                else {
                    setNextButton(true);
                }
                break;
            case 3:
                setNextButton(false);
                break;
            default:
                setNextButton(true);
        }
    }, [newOrderDetail, activeStep])

    const resetFormValue = async () => {
        setShowOrderModal(false);
        setSelectedClient();
        setNewOrderDetail({ participants_type: 'INDIVIDUAL', property_address_type: 'single', participants: [{ contact_same_as_borrower: true }], anticipated_close_date: null })
        setParticipantLists();
        setEntityType();
        setEntityState();
        setParticipantCount(1);
        setSelectedAddress();
        setNextButton(true);
        setActiveStep(0);
        setMapCenter({ lat: 40.8974984, lng: -97.6722622 });
        setOrderSteps([]);
        setAddedOrderId(undefined);
    }

    const getClientList = async () => {
        try {
            const newOrder = new NewOrder();
            const data = await newOrder.clients();
            if (data) {
                setClientLists(data.clients);
                return data;
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {

        }
    };

    const getUserList = async (clientID) => {
        try {
            const newOrder = new NewOrder();
            const response = await newOrder.users(clientID);
            if (response.status === "success") {
                setUserLists(response.users);
            }
            else {
                toast.error(response.error, { position: toast.POSITION.TOP_RIGHT });
            }
        }
        catch (e) {
            toast.info(e, { position: toast.POSITION.TOP_RIGHT });
        }
        finally {}
    }

    const getProductList = async () => {
        setProductLoader(true);
        try {
            const newOrder = new NewOrder();
            const response = await newOrder.productList(newOrderDetail.orderbyid);
            if (response.status === "success") {
                setProductLists(response.order_types);
            }
            else {
                toast.error(response.error, { position: toast.POSITION.TOP_RIGHT });
            }
        }
        catch (e) {
            toast.info(e, { position: toast.POSITION.TOP_RIGHT });
        }
        finally {
            setProductLoader(false);
        }
    }

    const getParticipantLists = async () => {
        try {
            const newOrder = new NewOrder();
            const response = await newOrder.participantList();
            if (response.status === "success") {
                setParticipantLists(response.roles);
            }
            else {
                toast.error(response.error, { position: toast.POSITION.TOP_RIGHT });
            }
        }
        catch (e) {
            toast.info(e, { position: toast.POSITION.TOP_RIGHT });
        }
        finally {}
    }

    const getEntityType = async () => {
        try {
            const newOrder = new NewOrder();
            const response = await newOrder.entityType();
            if (response.status === "success") {
                setEntityType(response.types);
            }
            else {
                toast.error(response.error, { position: toast.POSITION.TOP_RIGHT });
            }
        }
        catch (e) {
            toast.info(e, { position: toast.POSITION.TOP_RIGHT });
        }
        finally {}
    }

    const getEntityState = async () => {
        try {
            const newOrder = new NewOrder();
            const response = await newOrder.entityState();
            if (response.status === "success") {
                setEntityState(response.states);
            }
            else {
                toast.error(response.error, { position: toast.POSITION.TOP_RIGHT });
            }
        }
        catch (e) {
            toast.info(e, { position: toast.POSITION.TOP_RIGHT });
        }
        finally {}
    }

    const addNewOrder = async () => {
        try {
            const newOrder = new NewOrder();
            const form_data = new FormData();
            for (const property in newOrderDetail) {
                if (property === 'participants')
                    await form_data.append(property, JSON.stringify(newOrderDetail[property]));
                else
                    await form_data.append(property, newOrderDetail[property]);
            }
            const response = await newOrder.addNewOrder(form_data);
            if (response.status === "success") {
                setAddedOrderId(response.order.id);
                toast.success("New Order has been succesfully created", { position: toast.POSITION.TOP_RIGHT });
            }
            else {
                setAddedOrderId(0);
                toast.error(response.error, { position: toast.POSITION.TOP_RIGHT });
            }
        }
        catch (e) {
            setAddedOrderId(0);
            toast.info(e, { position: toast.POSITION.TOP_RIGHT });
        }
        // finally {
        //     resetFormValue();
        // }
    }

    const getMapKey = async () => {
        try {
            const company = new Company();
            const response = await company.current();
            setMapKey(response.settings.googleMapJsKey);
        }
        catch (e) {
            toast.info(e, { position: toast.POSITION.TOP_RIGHT });
        }
        finally {}
    }

    const verifiyAddress = async (payload, participantAddress = false, participantIndex) => {
        try {
            const newOrder = new NewOrder();
            let request = { street: `${payload.street_number}, ${payload.route}` };
            request.zipcode = payload.zip;
            request.street2 = payload.sublocality ? payload.sublocality : payload.city;
            const response = await newOrder.verifiyAddress(request);
            if (response.status === "success") {
                // setSelectedAddress(payload);
                // setNewOrderDetail({
                //     ...newOrderDetail,
                //     propertyaddress: `${payload.street_number}, ${payload.route}, ${payload.sublocality ? payload.sublocality : ''}`,
                //     propertycity: payload.city,
                //     propertystate: payload.state,
                //     propertyzipcode: payload.zipcode,
                //     pos_lat: payload.latlng.latitude,
                //     pos_long: payload.latlng.longitude,
                // })
            }
            else {
                toast.error(response.error, { position: toast.POSITION.TOP_RIGHT });
            }
            setSelectedAddress(payload);
            if (participantAddress) {
                const participantDetail = newOrderDetail.participants;
                participantDetail[participantIndex].participant_address = `${payload.street_number} ${payload.route} `;
                participantDetail[participantIndex].participant_city = payload.city;
                participantDetail[participantIndex].participant_state = payload.state;
                participantDetail[participantIndex].participant_zip = parseInt(payload.zip);

                setNewOrderDetail({ ...newOrderDetail, participants: participantDetail });
            }
            else {
                setNewOrderDetail({
                    ...newOrderDetail,
                    propertyaddress: `${payload.street_number} ${payload.route} `,
                    propertycity: payload.city,
                    propertystate: payload.state,
                    propertyzipcode: parseInt(payload.zip),
                    pos_lat: payload.latlng.latitude,
                    pos_long: payload.latlng.longitude,
                })
                setMapCenter({ lat: payload.latlng.latitude, lng: payload.latlng.longitude });

            }

        }
        catch (e) {
            toast.info(e, { position: toast.POSITION.TOP_RIGHT });
        }
        finally {}
    }

    const zipcodeLookup = async (zip, index = undefined) => {
        try {
            const order = new NewOrder();
            const response = await order.zipcodeLookup(zip);
            setNewOrderDetail({ ...newOrderDetail, });
            if (index) {
                const participantDetail = newOrderDetail.participants;
                participantDetail[index].participant_zip = response.ZIPCode;
                participantDetail[index].participant_city = response.City;
                participantDetail[index].participant_state = response.State;
                setNewOrderDetail({ ...newOrderDetail, participants: participantDetail });
            }
            else {

                newOrderDetail.propertyzipcode = response.ZIPCode;
                newOrderDetail.propertycity = response.City;
                newOrderDetail.propertystate = response.State;
                setNewOrderDetail({ ...newOrderDetail });
                setMapCenter({ lat: parseFloat(response.Latitude), lng: parseFloat(response.Longitude) });
            }
        }
        catch (e) {
            toast.info(e, { position: toast.POSITION.TOP_RIGHT });
        }
        finally {}
    }

    React.useEffect(() => {
        getClientList();
        getMapKey();
    }, []);

    const memoedValue = React.useMemo(
        () => ({
            selectedClient,
            setSelectedClient,
            clientLists,
            getUserList,
            userLists,
            newOrderDetail,
            setNewOrderDetail,
            getProductList,
            productLists,
            productLoader,
            participantLists,
            getParticipantLists,
            getEntityType,
            getEntityState,
            entityType,
            entityState,
            participantCount,
            setParticipantCount,
            addNewOrder,
            mapKey,
            verifiyAddress,
            selectedAddress,
            setSelectedAddress,
            activeStep,
            setActiveStep,
            nextButton,
            mapCenter,
            setMapCenter,
            setNextButton,
            showOrderModal,
            setShowOrderModal,
            zipcodeLookup,
            resetFormValue,
            orderSteps,
            setOrderSteps,
            addedOrderId
        }), [addedOrderId, selectedClient, nextButton, clientLists, userLists, newOrderDetail, participantCount, productLists, participantLists, productLoader, entityType, entityState, mapKey, selectedAddress, activeStep, mapCenter, showOrderModal])

    return (
        <NewOrderContext.Provider value={memoedValue}>
            {children}
        </NewOrderContext.Provider>);
};

export function useNewOrder() {
    return React.useContext(NewOrderContext);
}
