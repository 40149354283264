
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import RequiresAuth from 'common/authentication/RequiresAuth';
import { Route } from 'react-router-dom';

const route = (
  <Route requires="ADMIN" element={<RequiresAuth><ProtectedPage /></RequiresAuth>} />
);
export default route;

function ProtectedPage () {
  return (
    <>
      <Box sx={{ marginTop: '30vh' }}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Typography variant="h1">This page is only accessible by admin users.</Typography>
          <Typography variant="h3">Nothing to see here. Move along. Move along.</Typography>
        </Paper>
      </Box>
    </>
  );
}

