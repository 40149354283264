
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import { cl, isUndefinedOrNull } from '@twipped/utils';

const VoxRowRoot = styled('div', {
  name: 'VoxRow',
  slot: 'Root',
})`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: stretch;

  &.VoxRow-wrap {
    flex-wrap: wrap;
  }

  &.VoxRow-center {
    align-items: center;
    justify-content: center;
  }

  &.VoxRow-justify-left {
    justify-content: flex-start;
  }

  &.VoxRow-justify-center {
    justify-content: center;
  }

  &.VoxRow-justify-right {
    justify-content: flex-end;
  }

  &.VoxRow-justify-between {
    justify-content: space-between;
  }

  &.VoxRow-justify-around {
    justify-content: space-around;
  }

  &.VoxRow-justify-stretch {
    justify-content: stretch;
  }

  &.VoxRow-align-top {
    align-items: flex-start;
  }

  &.VoxRow-align-center {
    align-items: center;
  }

  &.VoxRow-align-bottom {
    align-items: flex-end;
  }

  &.VoxRow-align-stretch {
    align-items: stretch;
  }

`;


const Row = forwardRef(function Row ({
  center,
  justify,
  align,
  className,
  component,
  wrap = false,
  style,
  grow,
  shrink,
  basis,
  auto,
  fill,
  ...other
}, ref) {
  const Component = component || 'div';

  style = {
    ...style,
  };

  if (grow === true) grow = 1;
  if (grow === false) grow = 0;
  if (shrink === true) shrink = 1;
  if (shrink === false) shrink = 0;

  if (fill) {
    style.flex = `1 1 ${basis || 'auto'}`;
  } else {
    if (!isUndefinedOrNull(grow)) style.flexGrow = grow;
    if (!isUndefinedOrNull(shrink)) style.flexShrink = shrink;
    if (basis) style.flexBasis = basis;
    else if (auto) style.flexBasis = 'auto';
  }

  return (
    <VoxRowRoot
      {...other}
      as={Component}
      ref={ref}
      className={cl(
        className,
        center && 'VoxRow-center',
        justify && `VoxRow-justify-${justify}`,
        align && `VoxRow-align-${align}`,
        wrap && 'VoxRow-wrap',
      )}
      style={style}
    />
  );
});
Row.propTypes = {
  component: PropTypes.elementType,

  wrap: PropTypes.bool,

  center: PropTypes.bool,

  justify: PropTypes.oneOf([
    'left',
    'center',
    'right',
    'between',
    'around',
    'stretch',
  ]),

  align: PropTypes.oneOf([
    'top',
    'center',
    'bottom',
    'stretch',
  ]),

  grow: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),

  shrink: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),

  basis: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),

  auto: PropTypes.bool,

  fill: PropTypes.bool,
};

export default Row;
