
// POSIX Path Module from NodeJS, adapted for use in react
// https://github.com/nodejs/node/blob/69f487efc734f2b9bb67f46f99cd98e1c1e13d9b/lib/path.js

const CHAR_DOT = 46; /* . */
const CHAR_FORWARD_SLASH = 47; /* / */

const stringPrototypeCharCodeAt = (str, ...args) => String(str).charCodeAt(...args);
const stringPrototypeLastIndexOf = (str, ...args) => String(str).lastIndexOf(...args);
const stringPrototypeSlice = (str, ...args) => String(str).slice(...args);
const posixCwd = () => '/';
const isPosixPathSeparator = (code) => code === CHAR_FORWARD_SLASH;

export function resolve (...args) {
  let resolvedPath = '';
  let resolvedAbsolute = false;

  for (let i = args.length - 1; i >= -1 && !resolvedAbsolute; i--) {
    const path = i >= 0 ? args[i] : posixCwd();



    // Skip empty entries
    if (path.length === 0) {
      continue;
    }

    resolvedPath = `${path}/${resolvedPath}`;
    resolvedAbsolute =
      stringPrototypeCharCodeAt(path, 0) === CHAR_FORWARD_SLASH;
  }

  // At this point the path should be resolved to a full absolute path, but
  // handle relative paths to be safe (might happen when process.cwd() fails)

  // Normalize the path
  resolvedPath = normalizeString(resolvedPath, !resolvedAbsolute, '/',
    isPosixPathSeparator);

  if (resolvedAbsolute) {
    return `/${resolvedPath}`;
  }
  return resolvedPath.length > 0 ? resolvedPath : '.';
}

export function normalize (path) {

  if (path.length === 0) return '.';

  const pathIsAbsolute =
    stringPrototypeCharCodeAt(path, 0) === CHAR_FORWARD_SLASH;
  const trailingSeparator =
    stringPrototypeCharCodeAt(path, path.length - 1) === CHAR_FORWARD_SLASH;

  // Normalize the path
  path = normalizeString(path, !pathIsAbsolute, '/', isPosixPathSeparator);

  if (path.length === 0) {
    if (pathIsAbsolute) { return '/'; }
    return trailingSeparator ? './' : '.';
  }
  if (trailingSeparator) { path += '/'; }

  return pathIsAbsolute ? `/${path}` : path;
}

export function isAbsolute (path) {
  return path.length > 0 &&
         stringPrototypeCharCodeAt(path, 0) === CHAR_FORWARD_SLASH;
}

export function join (...args) {
  if (args.length === 0) { return '.'; }
  let joined;
  for (let i = 0; i < args.length; ++i) {
    const arg = args[i];

    if (arg.length > 0) {
      if (joined === undefined) { joined = arg; } else { joined += `/${arg}`; }
    }
  }
  if (joined === undefined) { return '.'; }
  return normalize(joined);
}

export function relative (from, to) {
  if (from === to) { return ''; }

  // Trim leading forward slashes.
  from = resolve(from);
  to = resolve(to);

  if (from === to) { return ''; }

  const fromStart = 1;
  const fromEnd = from.length;
  const fromLen = fromEnd - fromStart;
  const toStart = 1;
  const toLen = to.length - toStart;

  // Compare paths to find the longest common path from root
  const length = (fromLen < toLen ? fromLen : toLen);
  let lastCommonSep = -1;
  let i = 0;
  for (; i < length; i++) {
    const fromCode = stringPrototypeCharCodeAt(from, fromStart + i);
    if (fromCode !== stringPrototypeCharCodeAt(to, toStart + i)) { break; } else if (fromCode === CHAR_FORWARD_SLASH) { lastCommonSep = i; }
  }
  if (i === length) {
    if (toLen > length) {
      if (stringPrototypeCharCodeAt(to, toStart + i) === CHAR_FORWARD_SLASH) {
        // We get here if `from` is the exact base path for `to`.
        // For example: from='/foo/bar'; to='/foo/bar/baz'
        return stringPrototypeSlice(to, toStart + i + 1);
      }
      if (i === 0) {
        // We get here if `from` is the root
        // For example: from='/'; to='/foo'
        return stringPrototypeSlice(to, toStart + i);
      }
    } else if (fromLen > length) {
      if (stringPrototypeCharCodeAt(from, fromStart + i) ===
          CHAR_FORWARD_SLASH) {
        // We get here if `to` is the exact base path for `from`.
        // For example: from='/foo/bar/baz'; to='/foo/bar'
        lastCommonSep = i;
      } else if (i === 0) {
        // We get here if `to` is the root.
        // For example: from='/foo/bar'; to='/'
        lastCommonSep = 0;
      }
    }
  }

  let out = '';
  // Generate the relative path based on the path difference between `to`
  // and `from`.
  for (i = fromStart + lastCommonSep + 1; i <= fromEnd; ++i) {
    if (i === fromEnd ||
        stringPrototypeCharCodeAt(from, i) === CHAR_FORWARD_SLASH) {
      out += out.length === 0 ? '..' : '/..';
    }
  }

  // Lastly, append the rest of the destination (`to`) path that comes after
  // the common path parts.
  return `${out}${stringPrototypeSlice(to, toStart + lastCommonSep)}`;
}

export function dirname (path) {

  if (path.length === 0) { return '.'; }
  const hasRoot = stringPrototypeCharCodeAt(path, 0) === CHAR_FORWARD_SLASH;
  let end = -1;
  let matchedSlash = true;
  for (let i = path.length - 1; i >= 1; --i) {
    if (stringPrototypeCharCodeAt(path, i) === CHAR_FORWARD_SLASH) {
      if (!matchedSlash) {
        end = i;
        break;
      }
    } else {
      // We saw the first non-path separator
      matchedSlash = false;
    }
  }

  if (end === -1) { return hasRoot ? '/' : '.'; }
  if (hasRoot && end === 1) { return '//'; }
  return stringPrototypeSlice(path, 0, end);
}

export function basename (path, ext) {

  let start = 0;
  let end = -1;
  let matchedSlash = true;

  if (ext !== undefined && ext.length > 0 && ext.length <= path.length) {
    if (ext === path) { return ''; }
    let extIdx = ext.length - 1;
    let firstNonSlashEnd = -1;
    for (let i = path.length - 1; i >= 0; --i) {
      const code = stringPrototypeCharCodeAt(path, i);
      if (code === CHAR_FORWARD_SLASH) {
        // If we reached a path separator that was not part of a set of path
        // separators at the end of the string, stop now
        if (!matchedSlash) {
          start = i + 1;
          break;
        }
      } else {
        if (firstNonSlashEnd === -1) {
          // We saw the first non-path separator, remember this index in case
          // we need it if the extension ends up not matching
          matchedSlash = false;
          firstNonSlashEnd = i + 1;
        }
        if (extIdx >= 0) {
          // Try to match the explicit extension
          if (code === stringPrototypeCharCodeAt(ext, extIdx)) {
            if (--extIdx === -1) {
              // We matched the extension, so mark this as the end of our path
              // component
              end = i;
            }
          } else {
            // Extension does not match, so our result is the entire path
            // component
            extIdx = -1;
            end = firstNonSlashEnd;
          }
        }
      }
    }

    if (start === end) { end = firstNonSlashEnd; } else if (end === -1) { end = path.length; }
    return stringPrototypeSlice(path, start, end);
  }
  for (let i = path.length - 1; i >= 0; --i) {
    if (stringPrototypeCharCodeAt(path, i) === CHAR_FORWARD_SLASH) {
      // If we reached a path separator that was not part of a set of path
      // separators at the end of the string, stop now
      if (!matchedSlash) {
        start = i + 1;
        break;
      }
    } else if (end === -1) {
      // We saw the first non-path separator, mark this as the end of our
      // path component
      matchedSlash = false;
      end = i + 1;
    }
  }

  if (end === -1) { return ''; }
  return stringPrototypeSlice(path, start, end);
}

export function extname (path) {

  let startDot = -1;
  let startPart = 0;
  let end = -1;
  let matchedSlash = true;
  // Track the state of characters (if any) we see before our first dot and
  // after any path separator we find
  let preDotState = 0;
  for (let i = path.length - 1; i >= 0; --i) {
    const code = stringPrototypeCharCodeAt(path, i);
    if (code === CHAR_FORWARD_SLASH) {
      // If we reached a path separator that was not part of a set of path
      // separators at the end of the string, stop now
      if (!matchedSlash) {
        startPart = i + 1;
        break;
      }
      continue;
    }
    if (end === -1) {
      // We saw the first non-path separator, mark this as the end of our
      // extension
      matchedSlash = false;
      end = i + 1;
    }
    if (code === CHAR_DOT) {
      // If this is our first dot, mark it as the start of our extension
      if (startDot === -1) { startDot = i; } else if (preDotState !== 1) { preDotState = 1; }
    } else if (startDot !== -1) {
      // We saw a non-dot and non-path separator before our dot, so we should
      // have a good chance at having a non-empty extension
      preDotState = -1;
    }
  }

  if (startDot === -1 ||
      end === -1 ||
      // We saw a non-dot character immediately before the dot
      preDotState === 0 ||
      // The (right-most) trimmed path component is exactly '..'
      (preDotState === 1 &&
       startDot === end - 1 &&
       startDot === startPart + 1)) {
    return '';
  }
  return stringPrototypeSlice(path, startDot, end);
}

function normalizeString (path, allowAboveRoot, separator, isPathSeparator) {
  let res = '';
  let lastSegmentLength = 0;
  let lastSlash = -1;
  let dots = 0;
  let code = 0;
  for (let i = 0; i <= path.length; ++i) {
    if (i < path.length) { code = stringPrototypeCharCodeAt(path, i); } else if (isPathSeparator(code)) { break; } else { code = CHAR_FORWARD_SLASH; }

    if (isPathSeparator(code)) {
      if (lastSlash === i - 1 || dots === 1) {
        // NOOP
      } else if (dots === 2) {
        if (res.length < 2 || lastSegmentLength !== 2 ||
            stringPrototypeCharCodeAt(res, res.length - 1) !== CHAR_DOT ||
            stringPrototypeCharCodeAt(res, res.length - 2) !== CHAR_DOT) {
          if (res.length > 2) {
            const lastSlashIndex = stringPrototypeLastIndexOf(res, separator);
            if (lastSlashIndex === -1) {
              res = '';
              lastSegmentLength = 0;
            } else {
              res = stringPrototypeSlice(res, 0, lastSlashIndex);
              lastSegmentLength =
                res.length - 1 - stringPrototypeLastIndexOf(res, separator);
            }
            lastSlash = i;
            dots = 0;
            continue;
          } else if (res.length !== 0) {
            res = '';
            lastSegmentLength = 0;
            lastSlash = i;
            dots = 0;
            continue;
          }
        }
        if (allowAboveRoot) {
          res += res.length > 0 ? `${separator}..` : '..';
          lastSegmentLength = 2;
        }
      } else {
        if (res.length > 0) { res += `${separator}${stringPrototypeSlice(path, lastSlash + 1, i)}`; } else { res = stringPrototypeSlice(path, lastSlash + 1, i); }
        lastSegmentLength = i - lastSlash - 1;
      }
      lastSlash = i;
      dots = 0;
    } else if (code === CHAR_DOT && dots !== -1) {
      ++dots;
    } else {
      dots = -1;
    }
  }
  return res;
}


export default {
  resolve,
  normalize,
  isAbsolute,
  join,
  relative,
  dirname,
  basename,
  extname,
};
