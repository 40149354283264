
import { Route, Navigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Helmet from 'react-helmet';
import Button from '@mui/material/Button';

const route = <Route index element={<LandingPage />} />;
export default route;

function LandingPage() {
  return (
    <Navigate to="/login" replace />
  );
}

export const routes = Object.freeze({

})
