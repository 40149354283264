import React from 'react'

import {
  Document
} from 'core/models'

const DocumentContext = React.createContext({})

export function DocumentProvider({
  children
}) {
  const [isLoading, setLoading] = React.useState(false)
  const [curDocument, setCurDocument] = React.useState(null)
  const [orderInfo, setOrderDetails] = React.useState(null)
  const [signature, setSignature] = React.useState(null)

  async function loadDocument(txUUID) {
    setLoading(true)
    const { error, result } = await Document.load({ txUUID })
    setLoading(false)

    if (error) {
      return
    }

    setCurDocument(result)
  }

  async function loadOrderInfo() {
    setLoading(true)
    const { error, result } = await Document.getOrderInfo()
    setLoading(false)
    if (error) {
      return
    }
    setOrderDetails(result)
  }

  async function getParticipantSignature() {
    setLoading(true)
    const { error, result } = await Document.getSignature()
    setLoading(false)

    if (error) {
      return
    }
    setSignature(result)
  }

  const memoedValue = React.useMemo(() => ({
    isLoading,
    curDocument,
    signature,
    orderInfo,
    getParticipantSignature,
    loadDocument,
    loadOrderInfo,
  }), [isLoading, curDocument, signature, orderInfo
  ])

  return (
    <DocumentContext.Provider value={memoedValue}>
      {children}
    </DocumentContext.Provider>
  )
}

/**
 * @typedef {{isLoading: boolean,signature: Document, curDocument: Document, loadDocument: Function, signatureCanvas: Function, loadOrderInfo:Function, orderInfo: Document} UseDocument
 * @returns {UseDocument}
 */
export function useDocument() {
  return React.useContext(DocumentContext)
}
