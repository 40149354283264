import AbstractGC from './abstract';

export default class Vendor extends AbstractGC {

  async getAvailabeVendors(orderId) {
    return await this.get(`/vendors?orderid=${orderId}`);
  }

  async getStateVendors(state) {
    return await this.get(`/state/vendors/${state}`);
  }


  async getVendorDetail(vendorId) {
    return await this.get(`/vendor/${vendorId}`);
  }

  async getVendorOrder() {
    return await this.get(`/vendor/order/details/254831`);
  }

  async getAllStates() {
    return await this.get(`/states`);
  }

  async getVendorType() {
    return await this.get(`/vendor/types`);
  }

  async getVendorsBy(keyword, state, type) {

    let query = ''

    if(keyword) query+= `keyword=${keyword}&`
    if(state) query+= `state=${state}&`
    if(type) query+= `type=${type}&`

    return await this.get(`/get-vendors?${query}`);
  }

  async addVendor(payload) {
    return await this.post(`/vendor/add`, payload);
  }

  async assignVendor(vendorid, partid, vendorfee, due_date) {
    const form_data = new FormData();
    form_data.append('vendorid', vendorid);
    form_data.append('partid', partid);
    form_data.append('vendorfee', vendorfee);
    form_data.append('due_date', due_date);
    return await this.post(`/vendor/assign`, form_data);
  }

  async unassignVendor(vendorid, partid) {
    const form_data = new FormData();
    form_data.append('vendorid', vendorid);
    form_data.append('partid', partid);
    return await this.post(`/vendor/unassign`, form_data);
  }

  async enableVendor(vendorid, exclude) {

    console.log({
      vendorid,
      exclude
    })

    const form_data = new FormData();
    form_data.append('vendorid', vendorid);
    form_data.append('exclude', exclude);
    return await this.post(`/vendor/exclude`, form_data);
  }

  async activeVendor(vendorid, activate) {
    const form_data = new FormData();
    form_data.append('vendorid', vendorid);
    form_data.append('activate', activate);
    return await this.post(`/vendor/activate`, form_data);
  }

  async saveUpdateVendorProfilePicture(userid, profile) {
    const form_data = new FormData();
    form_data.append('userid', userid);
    form_data.append('profile', profile);
    return await this.post(`/user/profile-picture`, form_data);
  }
  async deleteVendorProfilePicture(userid) {
    const form_data = new FormData();
    form_data.append('userid', userid);
    return await this.post(`/user/delete-profile-picture`, form_data);
  }

  async addVendorProfile(payload) {
    return await this.post(`/vendor/add-profile`, payload);
  }

  async updateVendorProfile(payload) {
    return await this.post(`/vendor/update-profile`, payload);
  }

  async updateVendorField(payload) {
    const form_data = new FormData();
    form_data.append('vendorid', payload.vendorid);
    form_data.append('fieldname', payload.fieldname);
    form_data.append('fieldvalue', payload.fieldvalue);

    return await this.post(`/vendor/update/field`, form_data);
  }

  async updateVendor(payload) {
    return await this.post(`/vendor/update`, payload);
  }

  async updateStateDBA(payload) {
    const form_data = new FormData();
    form_data.append('vendor_id', payload.vendor_id);
    form_data.append('state', payload.state || '');
    if (payload.dba_name) form_data.append('dba_name', payload.dba_name);
    if (payload.city) form_data.append('city', payload.city);
    if (payload.address) form_data.append('address', payload.address);
    if (payload.zip_code) form_data.append('zip_code', payload.zip_code);
    return await this.post(`/vendor/update-dba`, form_data);
  }

  async fetchStateDBA(payload) {
    const form_data = new FormData();
    form_data.append('vendor_id', payload.vendor_id);
    return await this.post(`/vendor/fetch-dba`, form_data);
  }

  async deleteStateDBA(payload) {
    const form_data = new FormData();
    form_data.append('vendor_id', payload.vendor_id);
    form_data.append('state', payload.state || '');
    return await this.post(`/vendor/delete-dba`, form_data);
  }

  async updateVendorCertificate(payload) {
    return await this.post(`/user/update-certificate`, payload);
  }

  async deleteVendorCertificate(payload) {
    const form_data = new FormData();
    form_data.append('userid', payload.userid);
    form_data.append('uid', payload.uid);
    return await this.post(`/user/delete-certificate`, form_data);
  }

  async saveUpdateVendorFirmLogo(userid, profile) {
    const form_data = new FormData();
    form_data.append('userid', userid);
    form_data.append('logo', profile);
    return await this.post(`/user/firm-logo`, form_data);
  }

  async deleteVendorFirmLogo(userid) {
    const form_data = new FormData();
    form_data.append('userid', userid);
    return await this.post(`/user/delete-firm-logo`, form_data);
  }

  async saveUpdateVendorFirmSignature(userid, profile) {
    const form_data = new FormData();
    form_data.append('userid', userid);
    form_data.append('signature', profile);
    return await this.post(`/user/firm-signature`, form_data);
  }

  async deleteVendorFirmSignature(userid) {
    const form_data = new FormData();
    form_data.append('userid', userid);
    return await this.post(`/user/delete-firm-signature`, form_data);
  }

  async searchVenderByKey(word) {
    return await this.get(`/get-vendors?keyword=${word}`);
  }

  async deleteEmployee(userid, employeeid) {
    const form_data = new FormData();
    form_data.append('employeeid', employeeid);
    form_data.append('userid', userid);
    return await this.post(`/employee/delete`, form_data);
  }

  async sendEmail(payload) {
    return await this.post(`/user/send-email`, payload);
  }

  async saveEmployee(payload) {
    return await this.post(`/employee/add`, payload);
  }

  async getVendorProfile() {
    return await this.get(`/vendor-profile`);
  }
  async getVendorPublicProfile() {
    return await this.get(`/vendor-public-profile`);
  }
  async getTermsDetails() {
    return await this.get(`/vendor/terms/details`);
  }
  async getVendorFirmLogo() {
    return await this.get(`/vendor/firm-logo`);
  }

  async getFirmDetails(firm, employee) {
    return await this.get(`/public-vendor/${firm}/employee/${employee}`);
  }

  async saveVendorFirmLogo(logo) {
    const form_data = new FormData();
    form_data.append('logo', logo);
    return await this.post(`/vendor/firm-logo`, form_data);
  }

  async uploadVendorDocument(document, documentType, licensestate) {
    const form_data = new FormData();
    form_data.append('document', document);
    form_data.append('documentType', documentType);
    form_data.append('licensestate',licensestate);
    return await this.post(`/vendor-docs/upload`, form_data);
  }

  async getDeclineGuidelines() {
    return await this.get(`vendor/decline/guidelines`);
  }

  async addVendorEmployee(payload) {
    return await this.post(`/vendor/add-employee`, payload);
  }

  async deleteVendorEmployee(id) {
    const form_data = new FormData();
    form_data.append('employeeid', id);
    return await this.post(`/vendor/delete-employee`, form_data);
  }

  async sendingSms(orderid, phone, message) {
    const form_data = new FormData();
    form_data.append('order_id', orderid);
    form_data.append('phone_number', phone);
    form_data.append('message', message);
    return await this.post(`/user/send-sms`, form_data);
  }

  async getOrderType() {
    return await this.get(`/vendor/order/types`);
  }

  async getFeeSchedules(id) {
    return await this.get(`vendor/fee-schedules/get?vendorid=${id}`);
  }

  async addFeeSchedules(addFee) {
    const form_data = new FormData();
    form_data.append('turnaround_time', addFee.turnaround_time);
    form_data.append('approved', addFee.approved);
    form_data.append('fee', addFee.fee);
    form_data.append('orderTypeId', addFee.orderTypeId);
    return await this.post(`vendor/add-fee-schedule?vendorid=${addFee.userid}`, form_data);
  }

  async updatreFeeSchedules(addFee) {
    const form_data = new FormData();
    form_data.append('turnaround_time', addFee.turnaround_time);
    form_data.append('approved', addFee.approved);
    form_data.append('fee', addFee.fee);
    form_data.append('orderTypeId', addFee.orderTypeId);
    form_data.append('orderType', addFee.orderType);
    return await this.post(`vendor/update-fee-schedule?vendorid=${addFee.userid}`, form_data);
  }

  async deleteFeeSchedules(userid, orderTypeId) {
    const form_data = new FormData();
    form_data.append('order_type_id', orderTypeId);
    return await this.post(`vendor/delete-fee-schedule?vendorid=${userid}`, form_data);
  }

  async downloadVendorCertificate (filename) {
    return await this.get(`/vendorcert/${filename}`, {responseType: 'arraybuffer'});
  }

  async downloadVendorInsurance (filename) {
    return await this.get(`/vendorinsurance/${filename}`, {responseType: 'arraybuffer'});
  }

  async downloadVendorW9 () {
    return await this.get(`/vendorw9`, {responseType: 'arraybuffer'});
  }

  async getOrderFeeSchedules(userid, orderType) {
    return await this.get(`vendor/order-fee-schedules/get?vendorid=${userid}&orderytypeid=${orderType}`);
  }

}
