import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

// import './styles.scss'

export function TextArea({
  className = "",
  element,
  // value,
  // onChange = (value) => { }

}) {
  const [value,setValue] = React.useState('');

  React.useEffect(() =>{
    if(element.value !== undefined)
      setValue(element.value)
    else if (element.user_value)
      setValue(element.user_value)
  },[])

  const handleChange = (event) =>{
    setValue(event.target.value);
    element.value = event.target.value
  };

  return (
    <>
      {element.label !== "" &&
        <Grid container spacing={1} className="grid-container">
          <Grid xs={12} item={true}>
            <label dangerouslySetInnerHTML={{ __html: element.label }}/>
            <label className='required'>{element.required && '*'}</label>
          </Grid>
          <Grid xs={12} item={true} >
            <TextField
              error={element.error}
              className="full-width"
              multiline
              rows={4}
              variant="outlined"
              onChange={handleChange}
              value={value}
              style={{width:'100%'}}
              helperText={element.error_message}
              />
          </Grid>
        </Grid>
      }
    </>
  )
}
