import { BaseModel } from 'core/models/base'

export class Transaction extends BaseModel {
  status
  transaction_type
  uuid
  client_referance_number
  property_address
  property_unit
  property_city
  property_state
  property_zip

  /** @returns {Transaction} */
  static fromJSON(json) { return super.fromJSON(json) }
}
