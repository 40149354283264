import React from 'react'
import WebCam from 'react-webcam'
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import ErrorIcon from '@mui/icons-material/Error';
import './styles.scss'

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
}

export function FaceCaptureModal({
  show = true,
  onError = (err) => { },
  isVisualFailed = false,
  skipToMFA = () => {},
  onCapture = (imgSrc) => { },
  onClose = () => { },
}) {
  const webcamRef = React.useRef(null)

  const capture = React.useCallback(() => {
    const imgSrc = webcamRef?.current.getScreenshot()
    onCapture(imgSrc)
  }, [webcamRef])

  const getCaptureError = (e) => {
    onError(e)
  }

  return (
    <Dialog
      maxWidth="md"
      open={show}
       className='face-capture'>
      <div className='capture-header'>
        Verify your identity by centering your face in the photo frame, then click on {isVisualFailed ? <span>retake</span> : <span>capture</span>} photo.
      </div>
      <div className={isVisualFailed ? "capture-body capture-body-error" : "capture-body"}>
        <WebCam
          ref={webcamRef}
          screenshotFormat='image/jpeg'
          width={1280}
          videoConstraints={videoConstraints}
          onUserMediaError={getCaptureError}
        />
      </div>
      { isVisualFailed && (
        <div className='face-capture-error'>
          <ErrorIcon fontSize="small"/> <br/>We can't recognize you, please try again or select an alternative verification method.
        </div>
      )}
      <div className='capture-footer'>
        {!isVisualFailed && (
          <Button className='face-capture-btn' onClick={() => capture()}>Capture</Button>
        )}
        {isVisualFailed && (
          <>
            <Button className='face-capture-btn' onClick={() => capture()}>Retake Photo</Button>
            <br/>
            <Link className='face-skip-btn' onClick={() => skipToMFA()}>Try Other Verification Method</Link>
          </>
        )}
      </div>
    </Dialog>
  )
}

