import { plainToClass } from 'class-transformer'

export class BaseModel {
  id

  static fromJSON(json) {
    return plainToClass(this, json)
  }

  static fromJSONArray(arrJson) {
    if (!arrJson) return []
    return arrJson.map(json => this.fromJSON(json))
  }
}
