export const isDesktop = (window.innerWidth >= 992)

export const isMobile = (window.innerWidth < 992)

export const isSmallMobile = (window.innerWidth <= 576)

export function isNumber(variable) {
	const result = isFinite(variable)
	return result
}

export function isValidText(text, requiredTrim = false) {
	return (text && (requiredTrim ? (text.trim() !== "") : (text !== "")))
}

export function normalizeValue(value) {
	return value || "";
};

export function formatCurrency(amt) {
	return amt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function isUrl(value) {
	var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
		'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
	return !!pattern.test(value);
}

export function firstLetter(text) {
	if (!isValidText(text)) { return "" }
	return text.substr(0, 1).toUpperCase()
}

export function isEmail(email) {
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function isPhone(phonenumber) {
	const re = /\([0-9]{3}\) [0-9]{3}-[0-9]{4}/;
	return re.test(phonenumber);
}

export function isZipCode(zipcode) {
	const re = /[0-9]{5}/;
	return re.test(zipcode);
}

export function isValidPassword(password) {
	const regExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\-/@#$%{}^&_+=()!,<>?:;*]).{6,}$/
	//	const regExp = /^(?=.*[\\d])(?=.*[A-Z])[\\w!@#$%^&*-:;<>.,]{8,}$/
	return regExp.test(password)
}

export function isDomain(value) {
	if (!value) return false
	var regExp = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/)
	return value.match(regExp)
}

export function checkPasswordValidation(password1, password2) {
	let results = []
	if (!password1 || password1 === "" || !password2 || password2 === "") {
		results.push("Please fill passwords.")
	}

	if (!isValidPassword(password1)) {
		results.push("Password doesn't meet requirements.")
	}

	if (password1 !== password2) {
		results.push("Passwords do not match.")
	}

	return results
}

export function isValidPhoneNumber(phoneNumber) {
	if (!isValidText(phoneNumber, true)) { return false }
	const filteredPhoneNumber = phoneNumber.replace(/[^\d]/g, '');
	return filteredPhoneNumber.length >= 10
}

export function phoneNumberAutoComplete(value, previousValue) {
	// return nothing if no value
	if (!value) return value;

	// only allows 0-9 inputs
	const currentValue = value.replace(/[^\d]/g, '');
	const cvLength = currentValue.length;

	if (!previousValue || value.length > previousValue.length) {

		// returns: "x", "xx", "xxx"
		if (cvLength < 4) return currentValue;

		// returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
		if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

		// returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
		return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
	}
}


export function randomUUID() {
	let u = Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16)
	let result = [u.substr(0, 8), u.substr(8, 4), '4000-8' + u.substr(13, 3), u.substr(16, 12)].join('-')
	return result
}

export function zeroPad(number, base = 10) {
	var len = (String(base).length - String(number).length) + 1
	return len > 0 ? String(new Array(len).join('0') + number) : String(number)
}

export function colorFromText(text, invert = false) {
	function hashCode(text) {
		let hash = 0
		for (let i = 0; i < text.length; i++) {
			hash = text.charCodeAt(i) + ((hash << 5) - hash)
		}
		return hash;
	}

	const i = hashCode(text)
	let hex = i & 0x00FFFFFF
	if (invert) { hex = 0x00FFFFFF - hex }
	let c = (hex).toString(16).toUpperCase()

	const result = "00000".substring(0, 6 - c.length) + c
	return '#' + result
}

export function scrollToId(id) {
	if (!id) return
	const anchorEl = document.getElementById(id)
	if (anchorEl) {
		anchorEl.scrollIntoView()
	}
}

export function scrollIntoView(el, animate = true) {
	if (!el) return
	el.scrollIntoView({ behavior: animate && 'smooth' })
}

export function enableScroll(enable = true) {
	const scrollAttribute = "aria-scroll-disabled"
	if (enable) {
		if (!document.body.hasAttribute(scrollAttribute)) return

		document.body.removeAttribute(scrollAttribute)

		const body = document.body;
		const scrollY = body.style.top;
		body.style.position = '';
		body.style.top = '';
		window.scrollTo(0, parseInt(scrollY || '0') * -1);
	} else {
		if (document.body.hasAttribute(scrollAttribute)) return

		document.body.setAttribute(scrollAttribute, true)
		const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
		const body = document.body;
		body.style.position = 'fixed';
		body.style.top = `-${scrollY}`;
	}
}

export function range(start, stop, step = 1) {
	if (typeof stop == 'undefined') {
		// one param defined
		stop = start;
		start = 0;
	}

	if ((step > 0 && start > stop) || (step < 0 && start < stop)) {
		return [];
	}

	var result = [];
	for (var i = start; step > 0 ? i <= stop : i >= stop; i += step) {
		result.push(i);
	}

	return result;
}

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)