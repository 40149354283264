
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Helmet from 'react-helmet';

export default function NotFoundPage () {
  return (
    <>
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <Box sx={{ marginTop: '30vh' }}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Typography variant="h1">404 Not Found</Typography>
          <Typography variant="h3">Are you sure you got that url right?</Typography>
        </Paper>
      </Box>
    </>
  );
}

