import axios from 'axios'

import { isValidText } from 'utils'
import { Storage, ArrayHelper } from 'utils'
import getJWT from 'common/authentication/jwt';

const AxiosRequestInterceptor = async (config) => {
	let newConfig = config
	newConfig.headers = {
		// 'Content-Type': 'application/json',
		// 'Content-Type': '',
		// 'Access-Control-Allow-Credentials': true,
		// 'Access-Control-Allow-Origin': 'http://localhost:3000',
		// 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
		// 'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
		// ...newConfig.headers,
	}
	// newConfig.xsrfHeaderName = 'X-CSRF-Token'
	// newConfig.withCredentials = false

	return newConfig
}

axios.defaults.timeout = 30000 // 30 seconds timeout
// axios.interceptors.request.use(AxiosRequestInterceptor)

export class Api {
	static BASE_URL = process.env.REACT_APP_BASE_URL

	static formatError(value, errorCode) {
		if (!isValidText(value)) return null

		let message = value.replace('Error: ', '')
		if (message.includes("jwt malformed")) {
			message = 'Your credentials were not registered in system.'
		}
		if (message.includes("jwt expired") || errorCode === 401) {
			message = 'The session has expired. Please refresh the page and login to your account to return to the dashboard.'
		}
		if (message.slice(-1) !== '.') {
			return message + '.'
		}
		return message
	}

	static handleResponse(response) {
		try {
			if (!response.data.error) { return response.data }
			const { error } = response.data
			const { code: errorCode, message = "", errors = [] } = error || {}
			if (isValidText(message)) {
				return { success: false, error: Api.formatError(message, errorCode), errorCode }
			}

			if (ArrayHelper.isValid(errors)) {
				const { value, msg, param } = errors[0]
				return { success: false, error: Api.formatError(msg, errorCode), errorCode, errorValue: value }
			}
		} catch (error) {
		}
		return response.data
	}

	static parseError(error, placeholder = "Something went wrong") {
		if (error.isAxiosError) {
			return placeholder
		}

		if (error.message && error.message !== "") {
			return error.message
		}

		const { msg, param } = error.errors[0]
		return msg
	}

	static get headers() {
		delete axios.defaults.headers['Content-Type']
		if (!Storage.accessToken) {
			const jwt = getJWT()
			if (jwt)
				Storage.accessToken = jwt
			else
				return {...axios.defaults.headers, 'Access-Control-Allow-Origin': '*'}
		}
		return { ...axios.defaults.headers, Authorization: `Bearer ${Storage.accessToken}`, 'Access-Control-Allow-Origin': '*' }
	}

	static createConfiguration() {
		return {
			...axios.defaults,
			headers: Api.headers,
		}
	}

	static async refreshAccess() {
		const refreshToken = Storage.refreshToken
		if (!refreshToken) return { success: false }
		try {
			const { success, data, message } = Api.handleResponse(await axios.post(this.BASE_URL + '/refresh-access', { refreshToken: refreshToken }))
			if (success) Storage.accessToken = data.accessToken
			return { success }
		} catch (error) {
			return { success: false }
		}
	}

	static async get(path, params = undefined) {
		let query = ''
		if (params) {
			query = '?'
			let keys = Object.keys(params)
			for (let i = 0; i < keys.length; i++) {
				const key = keys[i]
				query += `${key}=${encodeURIComponent(params[key])}`
				if (i < keys.length - 1) {
					query += '&'
				}
			}
		}

		try {
			const requestPath = this.BASE_URL + path + query
			const response = await axios.get(requestPath, Api.createConfiguration())
			return Api.handleResponse(response)
		} catch (error) {
			// if (error.response && error.response.status === 401) {
			// 	const { success } = await Api.refreshAccess()
			// 	if (success) {
			// 		return await Api.get(path, params)
			// 	}
			// }

			throw error
		}
	}

	static async post(path, params = undefined, type="JSON") {
		try {
			var response;
			switch (type) {
				case "FORM":
					const form = new FormData()
					params.forEach(param => {
						form.append(param.name, param.value)
					})
					
					let config = Api.createConfiguration()
					config['headers'] = {
						...config['headers'],
						"Content-Type": "multipart/form-data",
					}

					response = await axios.post(this.BASE_URL + path, form, config)
					break
				default:
					response = await axios.post(this.BASE_URL + path, params, Api.createConfiguration())
			}
			return Api.handleResponse(response)
		} catch (error) {
			// if (error.response && error.response.status === 401) {
			// 	const { success } = await Api.refreshAccess()
			// 	if (success) {
			// 		return await Api.post(path, params)
			// 	}
			// }

			throw error
		}
	}

	static async downloadFile(path, params={}) {
		try {
			let config = Api.createConfiguration()
			const response = await axios({
				url: this.BASE_URL + path,
				method: 'GET',
				params: params,
				responseType: 'blob',
				...config
			})
			return Api.handleResponse(response)
		} catch (error) {
			// if (error.response && error.response.status === 401) {
			// 	const { success } = await Api.refreshAccess()
			// 	if (success) {
			// 		return await Api.uploadFiles(path, params)
			// 	}
			// }

			throw error
		}
	}
	static async uploadFiles(path, params) {
		try {
			const form = new FormData()
			params.forEach(param => {
				form.append(param.name, param.value)
			})
			
			let config = Api.createConfiguration()
			config['headers'] = {
				...config['headers'],
				"Content-Type": "multipart/form-data",
			}

			const response = await axios.post(this.BASE_URL + path, form, config)
			return Api.handleResponse(response)
		} catch (error) {
			// if (error.response && error.response.status === 401) {
			// 	const { success } = await Api.refreshAccess()
			// 	if (success) {
			// 		return await Api.uploadFiles(path, params)
			// 	}
			// }

			throw error
		}
	}

	static async put(path, params = undefined) {
		try {
			const response = await axios.put(this.BASE_URL + path, params, Api.createConfiguration())
			return Api.handleResponse(response)
		} catch (error) {
			// if (error.response && error.response.status === 401) {
			// 	const { success } = await Api.refreshAccess()
			// 	if (success) {
			// 		return await Api.put(path, params)
			// 	}
			// }

			throw error
		}
	}

	static async delete(path, params = null) {
		try {
			const response = await axios.delete(this.BASE_URL + path, { headers: Api.headers, data: params })
			return Api.handleResponse(response)
		} catch (error) {
			// if (error.response && error.response.status === 401) {
			// 	const { success } = await Api.refreshAccess()
			// 	if (success) {
			// 		return await Api.delete(path, params)
			// 	}
			// }

			throw error
		}
	}
}
