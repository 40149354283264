import { BaseModel } from '../base';

class Asset extends BaseModel {
}

export class DocSource extends BaseModel {
	name
	url
	assets = []

	/** @returns {DocSource} */
	static fromJSON(json) {
		const result = super.fromJSON(json)
		if (result) {
			// if (json.assets) result.assets = Asset.fromJSONArray(result.assets)
		}
		return result
	}
}
