import React from 'react'
import Grid from '@mui/material/Grid';

export function Label({
  element,
}) {
  return (
    <Grid container spacing={1} className="grid-container">
      <Grid xs={12} item={true}>
        <label dangerouslySetInnerHTML={{ __html: element.label }}></label>
      </Grid>
    </Grid>
  )
}
