import { render } from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Suspense, cloneElement } from 'react';
import { isFunction } from '@twipped/utils';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ProviderStack from './provider-stack';
import { SnackbarProvider } from "notistack";
import theme from './theme.js';
import useAuthentication, { AuthenticationProvider } from 'common/authentication';
import { ParticipantAuthProvider, VendorProvider, NewOrderProvider } from 'core'
import { CompanyProvider } from 'common/company';
import Loading from 'pages/loading';

import * as routes from './routes/{*.js,*/route.js}'; // eslint-disable-line import/no-unresolved
import NotFoundPage from './pages/notFound.js';

function DynamicRoutes({ children }) {
  const { isAuthenticated, canHas } = useAuthentication();

  const subroutes = Object.entries(routes).map(([key, route]) => {
    if (isFunction(route)) route = route({ isAuthenticated, canHas });
    return route && cloneElement(route, { key });
  });

  return (
    <Routes>
      {subroutes}
      {children}
    </Routes>
  );
}

const App = () => (
  <ProviderStack>
    <Router basename={process.env.APP_ROOT} />
    <Suspense fallback={<Loading />} />
    <AuthenticationProvider />
    <CompanyProvider />
    <ThemeProvider theme={theme} />
    <LocalizationProvider dateAdapter={AdapterDateFns} />
    <SnackbarProvider maxSnack={3} anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
      //TransitionComponent: 'Fade'
    }}/>

    <ParticipantAuthProvider>
    <VendorProvider>
    <NewOrderProvider>
      <CssBaseline />
        <DynamicRoutes>
          <Route path="*" element={<NotFoundPage />} />
        </DynamicRoutes>
        </NewOrderProvider>
        </VendorProvider>
    </ParticipantAuthProvider>
  </ProviderStack>
);



const mountPoint = document.createElement('div');
document.body.appendChild(mountPoint);
render(<App />, mountPoint);
