import React from 'react'

import { DocElementType } from 'core/models'

import { MediaField } from './media'
import { FileField } from './file'
import {TextFieldInput} from './text-field';
import { TextArea } from './text-area'
import { SelectBox } from './select-box'
import { RadioButton } from './radio-button'
import { SocialNumber } from './social-number'
import { DateField } from './date-field'
import { Upload } from './upload'
import { Label } from './label'
import { MediaLink } from './media-link'
import Checkbox from './check-box'

export function DocumentField(props) {

  return (
    <React.Fragment>
      {props.element.element_type === DocElementType.Media &&
        <MediaField {...props} />
      }
      {props.element.element_type === DocElementType.File &&
        <FileField {...props} />
      }
      {props.element.element_type === DocElementType.TextField &&
        <TextFieldInput {...props} />
      }
      {props.element.element_type === DocElementType.TextArea &&
        <TextArea {...props} />
      }
      {(props.element.element_type === DocElementType.SelectBox || props.element.element_type === DocElementType.SSelectBox) &&
        <SelectBox {...props} />
      }
      {props.element.element_type === DocElementType.RadioButton &&
        <RadioButton {...props} />
      }
      {props.element.element_type === DocElementType.SocialNumber &&
        <SocialNumber {...props} />
      }
      {props.element.element_type === DocElementType.DateField &&
        <DateField {...props} />
      }
      {props.element.element_type === DocElementType.Checkbox &&
        <Checkbox {...props} />
      }
      {props.element.element_type === DocElementType.Upload &&
        <Upload {...props} />
      }
      {props.element.element_type === DocElementType.Label &&
        <Label {...props} />
      }
      {props.element.element_type === DocElementType.MediaLink &&
        <MediaLink {...props} />
      }
      {/* {props.element.options &&
        props.element.options.map((option, idx) => {
          return (
            <React.Fragment key={idx}>
              {option.elements.map((element, index) => {
                return (
                  <DocumentField key={index} element={element} />
                )
              })}
            </React.Fragment>
          )
        })
      } */}
    </React.Fragment>
  )
}
